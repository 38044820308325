import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../locales";
import { ROUTES, LOCAL_STORAGE, PRODUCT_AVAILABLE } from "../../constants";
import classNames from "classnames";
import { addProductToOrder } from "../../store/orders/useCases/addProductToOrder/action";
import { addToWaiting } from "../../store/waitingList/useCases/addWaitingProduct/action";
import { setCartChange } from "../../store/orders/repository/actions";
import {
  selectAddToOrderLoading,
  selectAddToOrderLoadingId,
  selectCart,
  selectCartChange,
} from "../../store/orders/repository/selectors";
import { setAuthPopupOpen } from "../../store/user/repository/actions";
import { IProduct } from "../../store/products/repository/IProduct";
import { ICartProduct } from "../../store/orders/repository/ICartProduct";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { Tooltip } from "react-tooltip";
import { ImCart } from "react-icons/im";
import { BsCartXFill, BsFillCartCheckFill } from "react-icons/bs";
import Loader from "../loader/loader";
import "./buyButton.scss";
import "react-tooltip/dist/react-tooltip.css";

interface IProps {
  className: string;
  id: number;
  minimalCount?: number;
  children?: ReactNode;
  productAvailable?: string;
  product: IProduct;
  productAmount?: number;
}; 

const BuyButton: FC<IProps> = ({ className, children, id, productAvailable, product, minimalCount, productAmount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const cartChange = useSelector(selectCartChange);
  const { data } = useSelector(selectCart);
  const loadingState = useSelector(selectAddToOrderLoading);
  const loadingId = useSelector(selectAddToOrderLoadingId);

  const [isAdedded, setIsAdded] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(
    product.isWaiting || false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (productAvailable === PRODUCT_AVAILABLE.unavailable) {
      if (isLoggedIn === "true") {
        if (!isWaiting) {
          dispatch(addToWaiting(id));
          setIsWaiting(true);
        } else {
          navigate(ROUTES.NotifyProducts);
        }
      } else {
        dispatch(setAuthPopupOpen(true));
      }

      return;
    }

    if (isAdedded) {
      navigate(ROUTES.Cart);
      return;
    }

    if (isLoggedIn === "true") {
      dispatch(addProductToOrder(id, productAmount));
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);
      const newProduct = {
        product,
        amount: minimalCount ?? 1,
      };

      if (products) {
        const newProducts = JSON.parse(products);
        newProducts.push(newProduct);
        localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify(newProducts));
      } else {
        localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify([newProduct]));
      }
      dispatch(setCartChange(id));
    }

    setIsAdded(true);
  };

  const selectTooltipContent = () => {
    if (productAvailable === PRODUCT_AVAILABLE.unavailable) {
      return isWaiting ? "alreadyNotify" : "aviableNotify";
    } else {
      return isAdedded && !isLoading ? "alreadyInCart" : "";
    }
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      const productExists = data?.some(({ product }) => product.id === id);
      setIsAdded(productExists);
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);

      if (!products) return;
      const parsedProducts = JSON.parse(products);

      const productExists = parsedProducts.some(
        ({ product }: ICartProduct) => product.id === id
      );
      setIsAdded(productExists);
    }
  }, [id, data, isLoggedIn, cartChange]);

  useEffect(() => {
    if (id === loadingId) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [id, loadingId]);

  return (
    <>
      <Tooltip id="buy_button" />
      <button
        onClick={handleClick}
        type="button"
        aria-label="Buy Product"
        data-tooltip-content={`${i18n.t(selectTooltipContent())}`}
        data-tooltip-id="buy_button"
        className={classNames(className, "buy_button", {
          [`${className}--active_state`]: isAdedded,
          [`${className}--not_available_state`]:
            productAvailable === PRODUCT_AVAILABLE.unavailable,

          "buy_button--active_state": isAdedded,
          "buy_button--not_available_state":
            productAvailable === PRODUCT_AVAILABLE.unavailable && isWaiting,
          "buy_button--notify_state":
            productAvailable === PRODUCT_AVAILABLE.unavailable && !isWaiting,
          "buy_button--loading_state": isLoading,
          "buy_button--disabled_state": loadingState && !isAdedded,
        })}
      >
        {isLoading && <Loader btnStyle />}
        {isAdedded && productAvailable !== PRODUCT_AVAILABLE.unavailable ? (
          <>
            {children && <span>{i18n.t("addedToOrder")}</span>}
            <span className="font_icon">
              <BsFillCartCheckFill />
            </span>
          </>
        ) : (
          <>
            {productAvailable === PRODUCT_AVAILABLE.unavailable ? (
              <span className="font_icon">
                <BsCartXFill />
              </span>
            ) : (
              <span className="buy_button__icon font_icon">
                <ImCart />
                {children && <span>{children}</span>}
              </span>
            )}
          </>
        )}
      </button>
    </>
  );
};

export default BuyButton;



// import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import i18n from "../../locales";
// import { ROUTES, LOCAL_STORAGE, PRODUCT_AVAILABLE } from "../../constants";
// import classNames from "classnames";
// import { addProductToOrder } from "../../store/orders/useCases/addProductToOrder/action";
// import { addToWaiting } from "../../store/waitingList/useCases/addWaitingProduct/action";
// import { setCartChange } from "../../store/orders/repository/actions";
// import { selectAddToOrderLoading, selectAddToOrderLoadingId, selectCart, selectCartChange, } from "../../store/orders/repository/selectors";
// import { setAuthPopupOpen } from "../../store/user/repository/actions";
// import { IProduct } from "../../store/products/repository/IProduct";
// import { ICartProduct } from "../../store/orders/repository/ICartProduct";
// import { selectisLoggedIn } from "../../store/user/repository/selectors";
// import { Tooltip } from "react-tooltip";
// import { ImCart } from "react-icons/im";
// import { BsCartXFill, BsFillCartCheckFill } from "react-icons/bs";
// import Loader from "../loader/loader";
// import "./buyButton.scss";
// import "react-tooltip/dist/react-tooltip.css";
// import Popup from "../popup/popup";
// import CartProduct from "../cartProduct/cartProduct";

// interface IProps {
//   className: string;
//   id: number;
//   minimalCount?: number;
//   children?: ReactNode;
//   productAvailable?: string;
//   product: IProduct;
// };

// const BuyButton: FC<IProps> = ({ className, children, id, productAvailable, product, minimalCount, }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const isLoggedIn = useSelector(selectisLoggedIn);
//   const cartChange = useSelector(selectCartChange);
//   const { data } = useSelector(selectCart);
//   const loadingState = useSelector(selectAddToOrderLoading);
//   const loadingId = useSelector(selectAddToOrderLoadingId);

//   const [isAdedded, setIsAdded] = useState<boolean>(false);
//   const [isWaiting, setIsWaiting] = useState<boolean>(product.isWaiting || false);
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [amount, setAmount] = useState<number>(0);

//   const buttonRef = useRef<HTMLButtonElement | null>(null);
//   const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

//   const handleOpen = useCallback(() => {
//     setConfirmOpen(true);
//   }, []);

//   const handleClose = useCallback(() => {
//     setConfirmOpen(false);
//   }, []);

//   const handleClick = () => {
//     if (productAvailable === PRODUCT_AVAILABLE.unavailable) {
//       if (isLoggedIn === "true") {
//         if (!isWaiting) {
//           dispatch(addToWaiting(id));
//           setIsWaiting(true);
//         } else {
//           navigate(ROUTES.NotifyProducts);
//         }
//       } else {
//         dispatch(setAuthPopupOpen(true));
//       }

//       return;
//     }

//     if (isAdedded) {
//       // navigate(ROUTES.Cart);
//       handleOpen();
//       return;
//     }

//     if (isLoggedIn === "true") {
//       dispatch(addProductToOrder(id));
//       setTimeout(()=>handleOpen(), 100);
//     } else {
//       const products = localStorage.getItem(LOCAL_STORAGE.cart);
//       const newProduct = { product, amount: minimalCount ?? 1 };

//       if (products) {
//         const newProducts = JSON.parse(products);
//         newProducts.push(newProduct);
//         localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify(newProducts));
//       } else {
//         localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify([newProduct]));
//       }
//       dispatch(setCartChange(id));
//     }

//     setIsAdded(true);
//   };

//   const selectTooltipContent = () => {
//     if (productAvailable === PRODUCT_AVAILABLE.unavailable) {
//       return isWaiting ? "alreadyNotify" : "aviableNotify";
//     } else {
//       return isAdedded && !isLoading ? "alreadyInCart" : "";
//     }
//   };

//   useEffect(() => {
//     if (isLoggedIn === "true") {
//       const productExists = data?.some(({ product }) => product.id === id);
//       setIsAdded(productExists);

//       const currentProductAmount = data?.find(({ product }) => product.id === id)?.amount;
//       setAmount(currentProductAmount || 0);
//     } else {
//       const products = localStorage.getItem(LOCAL_STORAGE.cart);

//       if (!products) return;
//       const parsedProducts = JSON.parse(products);

//       const productExists = parsedProducts.some(
//         ({ product }: ICartProduct) => product.id === id
//       );
//       setIsAdded(productExists);
//     }
//   }, [id, data, isLoggedIn, cartChange]);

//   useEffect(() => {
//     if (id === loadingId) {
//       setIsLoading(true);
//     } else {
//       setIsLoading(false);
//     }
//   }, [id, loadingId]);

//   return (
//     <>
//       <Tooltip id="buy_button" />
//       <button
//         ref={buttonRef}
//         onClick={handleClick}
//         type="button"
//         aria-label="Buy Product"
//         data-tooltip-content={`${i18n.t(selectTooltipContent())}`}
//         data-tooltip-id="buy_button"
//         className={classNames(className, "buy_button", {
//           [`${className}--active_state`]: isAdedded,
//           [`${className}--not_available_state`]:
//             productAvailable === PRODUCT_AVAILABLE.unavailable,

//           "buy_button--active_state": isAdedded,
//           "buy_button--not_available_state":
//             productAvailable === PRODUCT_AVAILABLE.unavailable && isWaiting,
//           "buy_button--notify_state":
//             productAvailable === PRODUCT_AVAILABLE.unavailable && !isWaiting,
//           "buy_button--loading_state": isLoading,
//           "buy_button--disabled_state": loadingState && !isAdedded,
//         })}
//       >
//         {isLoading && <Loader btnStyle />}
//         {isAdedded && productAvailable !== PRODUCT_AVAILABLE.unavailable ? (
//           <>
//             {children && <span>{i18n.t("addedToOrder")}</span>}
//             <span className="font_icon">
//               <BsFillCartCheckFill />
//             </span>
//           </>
//         ) : (
//           <>
//             {productAvailable === PRODUCT_AVAILABLE.unavailable ? (
//               <span className="font_icon">
//                 <BsCartXFill />
//               </span>
//             ) : (
//               <span className="buy_button__icon font_icon">
//                 <ImCart />
//                 {children && <span>{children}</span>}
//               </span>
//             )}
//           </>
//         )}
//       </button>
//       {/* <div>
//         <Popup
//           isOpen={isConfirmOpen}
//           handleClose={handleClose}
//           triggerRef={buttonRef}
//           size="lg"
//         >
//           <CartProduct
//             key={product.id}
//             initialAmount={amount}
//             isPrecart={true}
//             handleClose={handleClose}
//             {...product}
//           />
//         </Popup>
//       </div> */}
//     </>
//   );
// };

// export default BuyButton;
