import i18n from "../../locales";
import classNames from "classnames";
import { FC, useCallback } from "react";
import { UseFormSetValue } from "react-hook-form";
import Select, { Props } from "react-select";
import "./customSelect.scss";

export interface IProps extends Props {
  name: string;
  setValue?: UseFormSetValue<any>;
  isError?: boolean;
  onValueChange?: (selectedOption: any) => void;
  mod?: "secondary";
}

const CustomSelect: FC<IProps> = ({
  setValue,
  onValueChange,
  name,
  isError,
  onChange,
  mod,
  ...props
}) => {
  const handleSelectChange = useCallback(
    (selectedOption: any) => {
      setValue && setValue(name, selectedOption?.value);
      onValueChange && onValueChange(selectedOption);
    },
    [name, setValue, onValueChange]
  );

  return (
    <Select
      className={classNames("custom_select", {
        "custom_select--error_mod": isError,
        "custom_select--secondary_mod": mod === "secondary",
        "custom_select--primary_mod": !mod,
      })}
      classNamePrefix="custom_select"
      placeholder={`${i18n.t("select")}...`}
      unstyled={true}
      onChange={onChange ? onChange : handleSelectChange}
      {...props}
    />
  );
};

export default CustomSelect;
