import { Link } from "react-router-dom";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { ROUTES } from "../../constants";
import Image from "../image/image";
import classNames from "classnames";
import "./categoryCard.scss";

const CategoryCard = ({
  id,
  name,
  smallImage,
  bigImage,
  childCategories,
}: ICatalog) => {
  const linkTo =
    childCategories && childCategories.length > 0
      ? `/${ROUTES.Categories}/${id}`
      : `/${ROUTES.ProductList}/${id}`;
  const hasBigImage = bigImage && !smallImage;

  return (
    <Link className="category_card" to={linkTo}>
      <div
        className={classNames("category_card__wrap", {
          "category_card__wrap--big_mod": hasBigImage,
        })}
      >
        <div className="category_card__image">
          {smallImage && <Image src={smallImage} />}
          {hasBigImage && <Image src={bigImage} />}
        </div>
      </div>
      <div className="category_card__name">{name}</div>
    </Link>
  );
};

export default CategoryCard;
