import { IResponseProduct } from "./IResponseProduct";
import { IProduct } from "./IProduct";
import { IProductSettings } from "./IProductSettings";
import { ISaleProducts } from "./ISaleProducts";
import { IPrices } from "./IPrices";
import { IFilter } from "./IFilter";

export const saveProducts = (payload: IResponseProduct) => ({
  type: "SAVE_PRODUCTS",
  payload,
});
export const setProductsLoading = (payload: boolean) => ({
  type: "SET_PRODUCTS_LOADING",
  payload,
});
export const setHiddenProductsLoading = (payload: boolean) => ({
  type: "SET_HIDDEN_PRODUCTS_LOADING",
  payload,
});
export const setProductsFinish = (payload: boolean) => ({
  type: "SET_PRODUCTS_FINISH",
  payload,
});
export const saveProductsPrices = (payload: IPrices) => ({
  type: "SAVE_PRODUCTS_PRICES",
  payload,
});

export const saveFilters = (payload: IFilter[]) => ({
  type: "SAVE_FILTERS",
  payload,
});

export const saveCurrentSettings = (payload: IProductSettings ) => ({
  type: "SAVE_CURRENT_SETTINGS",
  payload,
});

export const saveSingleProduct = (payload: IProduct | null) => ({
  type: "SAVE_SINGLE_PRODUCT",
  payload,
});
export const setSingleProductLoading = (payload: boolean) => ({
  type: "SAVE_SINGLE_PRODUCT_LOADING",
  payload,
});

export const saveProductCharacteristics = (payload: IProduct) => ({
  type: "SAVE_PRODUCT_CHARACTERISTICS",
  payload,
});
export const setProductCharacteristicsLoading = (payload: boolean) => ({
  type: "SAVE_PRODUCT_CHARACTERISTICS_LOADING",
  payload,
});

export const saveProductDescription = (payload: IProduct) => ({
  type: "SAVE_PRODUCT_DESCRIPTION",
  payload,
});
export const setProductDescriptionLoading = (payload: boolean) => ({
  type: "SAVE_PRODUCT_DESCRIPTION_LOADING",
  payload,
});

export const saveSaleProducts = (payload: ISaleProducts) => ({
  type: "SAVE_SALE_PRODUCTS",
  payload,
});
export const setSaleProductsLoading = (payload: boolean) => ({
  type: "SET_SALE_PRODUCTS_LOADING",
  payload,
});

export const setHiddenSaleProductsLoading = (payload: boolean) => ({
  type: "SET_HIDEN_SALE_PRODUCTS_LOADING",
  payload,
});

export const saveCategoryFilterType = (payload: string) => ({
  type: "SAVE_CATEGORY_FILTER_TYPE",
  payload,
});
