import { IRootState } from "../../store";

export const selectPromoProducts = (state: IRootState) =>
  state.promoProducts.promoList;

export const selectSinglePromo = (state: IRootState) =>
  state.promoProducts.singlePromo;

export const selectPromotionList = (state: IRootState) =>
  state.promoProducts.promotionList;

export const selectPromotionCount = (state: IRootState) =>
  state.promoProducts.promoCount;

export const selectPromoProductsLoading = (state: IRootState) =>
  state.promoProducts.isPromoProductsLoading;

export const selectHiddenPromoProductsLoading = (state: IRootState) =>
  state.promoProducts.isHiddenPromoProductsLoading;
