import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveUser,
  saveToken,
  setAuthPopupOpen,
  setConfirmation,
  setPasswordPopupOpen,
  setLoggedIn,
} from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import { LOCAL_STORAGE } from "../../../../constants";
import i18n from "../../../../locales";

interface IConfirmPasswordWorker {
  type: string;
  payload: {
    payload: string;
  };
}

function* confirmPasswordWorker({
  payload,
}: IConfirmPasswordWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const params = {
      token: localStorage.getItem(LOCAL_STORAGE.token),
      password: payload,
    };

    const { data, isError, message } = yield call(
      requester.post,
      links.confirmPassword,
      params
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveUser(data.user));
      localStorage.setItem(LOCAL_STORAGE.user, JSON.stringify(data.user));
      yield put(saveToken(data.token));
      localStorage.setItem(LOCAL_STORAGE.token, data.token);
      yield put(setLoggedIn("true"));
      localStorage.setItem(LOCAL_STORAGE.loggedIn, "true");

      yield put(setPasswordPopupOpen(false));
      yield put(setConfirmation(false));
      yield put(setAuthPopupOpen(false));

      createNotification({
        title: i18n.t("success"),
        message: "",
        type: "success",
      });
    }
  } catch (error) {
    console.error("confirmPasswordWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* confirmPasswordWatcher() {
  yield takeLatest("@saga/CONFIRM_PASSWORD", confirmPasswordWorker);
}
