import { IRootState } from "../../store";

export const selectPriceList = (state: IRootState) => state.priceList.priceList;

export const selectPriceListLoading = (state: IRootState) =>
  state.priceList.isPriceListLoading;

export const selectShortPriceList = (state: IRootState) =>
  state.priceList.shortPriceList;

export const selectShortPriceListLoading = (state: IRootState) =>
  state.priceList.isShortPriceListLoading;
