import { FC } from "react";
import Image from "../image/image";
import { IDescription } from "../../store/products/repository/IDescription";

interface IProps {
  main?: IDescription | null;
  secondary?: IDescription | null;
}

const DoubleColumn: FC<IProps> = ({ main, secondary }) => {
  const hasMainInfo = main?.title || main?.description;
  const hasSecondaryInfo = secondary?.title || secondary?.description;

  return (
    <>
      <div className="images_descriptions__column">
        {hasMainInfo && (
          <div className="images_descriptions__content">
            {main?.title && (
              <h3 className="images_descriptions__title">{main?.title}</h3>
            )}
            {main?.description && (
              <div className="images_descriptions__text">
                {main?.description}
              </div>
            )}
          </div>
        )}
        {main?.image && (
          <Image
            imgClass="images_descriptions__image"
            src={main?.image}
            alt={main?.title ?? " "}
          />
        )}
      </div>
      <div className="images_descriptions__column">
        {hasSecondaryInfo && (
          <div className="images_descriptions__content">
            {secondary?.title && (
              <h3 className="images_descriptions__title">{secondary?.title}</h3>
            )}
            {secondary?.description && (
              <div className="images_descriptions__text">
                {secondary?.description}
              </div>
            )}
          </div>
        )}
        {secondary?.image && (
          <Image
            imgClass="images_descriptions__image"
            src={secondary?.image}
            alt={secondary?.title ?? " "}
          />
        )}
      </div>
    </>
  );
};

export default DoubleColumn;
