import { ICatalogHistory } from "./ICatalogHistory";
import { ICatalog } from "./ICatalog";

export const saveCatalog = (payload: ICatalog[]) => ({
  type: "SAVE_CATALOG",
  payload,
});

export const saveCatalogHistory = (payload: ICatalogHistory[] | []) => ({
  type: "SAVE_CATALOG_HISTORY",
  payload,
});
