import { FC, useEffect, useState } from "react";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import Image from "../image/image";
import "./promotionCard.scss";
import { IPromo } from "../../store/products/repository/IPromo";
import i18n from "../../locales";
import { useTimer } from "../../customHooks/useTimer";

interface IProps {
  item: IPromo;
};

const PromotionCard: FC<IProps> = ({ item }) => {
  const { title, id, endDate = '', image } = item;
  const { timeLeft, getDayWord, isExpired } = useTimer(endDate);

  return (
    <Link to={`/${ROUTES.SinglePromotionPage.replace(':id', String(id))}`} className="promotion_card">
      <div className="promotion_card__wrap">
        <Image imgClass="promotion_card__image" src={image?.fileUrl || '/favicon-310.png'} />
      </div>
      <div className="promotion_card__content">
        <div className="promotion_card__wrap1">
          <h2 title={title} className="promotion_card__title">
            {title}
          </h2>
        </div>
        {!isExpired && <div className="promotion_card__separator"></div>}
        {!isExpired && (
          <div className="promotion_card__date">
            <div className="promotion_card__days">
              {timeLeft.days ? (
                <>
                  <span className="promotion_card__timeInDay">{timeLeft.days} {getDayWord(timeLeft.days)}</span>
                  <div className="promotion_card__remaining">{timeLeft && i18n.t('left')}</div>
                </>
              ) : (
                <>
                  <span className="promotion_card__time">
                    <span className="time_block">{String(timeLeft.hours).padStart(2, '0')}</span>:
                    <span className="time_block">{String(timeLeft.minutes).padStart(2, '0')}</span>:
                    <span className="time_block">{String(timeLeft.seconds).padStart(2, '0')}</span>
                  </span>
                  <div className="promotion_card__remaining">{timeLeft && i18n.t('left')}</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default PromotionCard;
