import ContactsSection from "../../ui-kit/contactsSection/contactsSection";
import FeedbackForm from "../../ui-kit/feedbackForm/feedbackForm";

const ContactsPage = () => {
  return (
    <>
      <ContactsSection />
      <FeedbackForm />
    </>
  );
};

export default ContactsPage;
