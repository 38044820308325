import i18n from "../../locales";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrders,
  selectOrdersLoading,
  selectOrdersPage,
} from "../../store/orders/repository/selectors";
import { saveOrdersPage } from "../../store/orders/repository/actions";
import { getOrders } from "../../store/orders/useCases/getOrders/action";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { TOrderStatus } from "../../types/orderStatus";
import UnregisteredMessage from "../unregisteredMessage/unregisteredMessage";
import OrderItem from "../orderItem/orderItem";
import Pagination from "../pagination/pagination";
import { PRODUCTS_PER_PAGE } from "../../constants";
import Loader from "../loader/loader";
import OrdersTabButton from "./ui/ordersTabButton";
import "./ordersSection.scss";

const OrdersSection = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const ordersPage = useSelector(selectOrdersPage);
  const ordersLoading = useSelector(selectOrdersLoading);
  const { data, count } = useSelector(selectOrders);
  const [activeItem, setActiveItem] = useState(ordersPage ?? 1);
  const [orderStatus, setOrderStatus] = useState<TOrderStatus>("Pending");

  const paginationClick = (page: number) => {
    dispatch(getOrders(page, orderStatus));
    dispatch(saveOrdersPage(page));
    setActiveItem(page);
  };

  const handleTabClick = (status: TOrderStatus) => {
    dispatch(getOrders(1, status));
    setOrderStatus(status);
    setActiveItem(1);
  };
  
  if (isLoggedIn !== "true") return <UnregisteredMessage />;
  return (
    <section className="orders">
      {ordersLoading && <Loader />}
      <div className="orders__wrapper">
        <div className="orders__nav">
          <OrdersTabButton
            isActive={orderStatus === "Pending"}
            handleClick={() => handleTabClick("Pending")}
          >
            {i18n.t("currentOrders")}
          </OrdersTabButton>
          <OrdersTabButton
            isActive={orderStatus === "Closed"}
            handleClick={() => handleTabClick("Closed")}
          >
            {i18n.t("historyOrders")}
          </OrdersTabButton>
        </div>
        {data.length ? (
          <div className="orders__body">
            <ul className="orders__list">
              {data.map((order) => (
                <li className="orders__item" key={order.id}>
                  <OrderItem {...order} />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="orders__message">
            {orderStatus === "Closed" ? (
              <p>{i18n.t("emptyClosedOrders")}</p>
            ) : (
              <>
                <p>{i18n.t("emptyOrders1")}</p>
                <p>{i18n.t("emptyOrders2")}</p>
              </>
            )}
          </div>
        )}
        {count > PRODUCTS_PER_PAGE && (
          <div>
            <Pagination
              pagesCount={count}
              perPage={PRODUCTS_PER_PAGE}
              activeItem={activeItem}
              onClick={paginationClick}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default OrdersSection;
