import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import { selectGroupProducts } from "../../store/groupProducts/repository/selectors";
import { savePaginationGroupId } from "../../store/groupProducts/repository/actions";
import GroupProductsTabs from "./ui/groupProductTabs";

const GroupProducts = () => {
  const { data } = useSelector(selectGroupProducts);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(savePaginationGroupId(undefined));
  }, [pathname]);

  return (
    <div className="group_products">
      {data.map(({ categories, productGroup }) => {
        const { id, title, products } = productGroup;

        return (
          <GroupProductsTabs
            key={id}
            groupId={id}
            mod="group"
            title={title}
            products={products}
            categories={categories}
          />
        );
      })}
    </div>
  );
};

export default GroupProducts;
