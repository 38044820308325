import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { savePromotionList, setPromoProductsLoading } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

interface IGetPromo {
  page: number;
  perPage: number;
};

function* getPromoListWorker(action: { type: string; payload: IGetPromo }): SagaIterator {
  try {
    yield put(setPromoProductsLoading(true));
    const { page, perPage } = action.payload;
    const { data, isError, message } = yield call(requester.get, links.promotionList, { page, perPage });
    if (isError) {
      createNotification({ title: i18n.t("error"), message, type: "danger" });
    } else {
      yield put(savePromotionList(data));
    }
  } catch (error) {
    console.error("getPromoListWorker: ", error);
  } finally {
    yield put(setPromoProductsLoading(false));
  }
};

export function* getPromoListWatcher() {
  yield takeLatest("@saga/GET_PROMO_LIST", getPromoListWorker);
};
