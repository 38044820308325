import { useState, useEffect } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import { useScrollDirection } from "../../customHooks";
// components
import { AiFillHeart } from "react-icons/ai";
import Logo from "../logo/logo";
import NavBar from "../navBar/navBar";
import Social from "../social/social";
import LangButton from "../langButton/langButton";
import SearchForm from "../searchForm/searchForm";
import CatalogButton from "../catalogButton/catalogButton";
import ThemeButton from "../themeButton/themeButton";
import UserButton from "../userButton/userButton";
import CartButton from "../cartButton/cartButton";
import BurgerButton from "../burgerButton/burgerButton";
import MobMenu from "../mobMenu/mobMenu";
import "./header.scss";

const Header = () => {
  const [isScrollState, setScrollState] = useState(false);
  const scrollDirection = useScrollDirection();
  const scrollStartPoint = 10;
  const location = useLocation();

  const handleScroll = () => {
    const windowScrollY = window.scrollY;
    if (windowScrollY > scrollStartPoint) {
      setScrollState(true);
    } else {
      setScrollState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={classNames("header", {
        "header--scroll_state": isScrollState,
        "header--hidden_mod": scrollDirection === "down",
      })}
    >
      <MobMenu />
      <div className="header__menu_search">
        <SearchForm />
      </div>
      <div className="header__wrapper">
        <div className="header_in">
          <div className="header__wrap">
            <div className="header__row">
              <div className="header__column header__column--logo_mod">
                <Link to={ROUTES.Home} className="header__logo">
                  <Logo />
                </Link>
              </div>
              <div className="header__column header__column--desktop_mod">
                <NavBar />
              </div>
              <div className="header__column header__column--menu_mod">
                <div className="header__container">
                  <div className="header__lang">
                    <LangButton />
                  </div>
                  <div className="header__burger">
                    <BurgerButton />
                  </div>
                </div>
              </div>
              <div className="header__column header__column--desktop_mod">
                <CatalogButton />
              </div>
              <div className="header__column header__column--desktop_mod">
                <SearchForm />
              </div>
              <div className="header__column header__column--actions_mod">
                <div className="header__actions">
                  <ul className="header__list">
                    <li className="header__item">
                      <Link
                        className={classNames("header__like", {
                          "header__like--active_state":
                            location.pathname === ROUTES.Favorites,
                        })}
                        to={ROUTES.Favorites}
                      >
                        <span>
                          <AiFillHeart />
                        </span>
                      </Link>
                    </li>
                    <li className="header__item">
                      <CartButton />
                    </li>
                    <li className="header__item">
                      <UserButton />
                    </li>
                    <li className="header__item header__item--desktop_mod">
                      <ThemeButton />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
