import {
  FC,
  ReactNode,
  memo,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { IFilterValue } from "../../store/products/repository/IFilterValue";
import classNames from "classnames";
import { transformValueToVH } from "../../utils/calculateHeight";
import { FiChevronDown } from "react-icons/fi";
import "./filterItem.scss";

interface IProps {
  children: ReactNode;
  name: string;
  onSpoilerClose?: () => void;
  values?: IFilterValue[];
  selectIsOpen?: boolean;
}

const FilterItem: FC<IProps> = ({
  children,
  name,
  values,
  selectIsOpen,
  onSpoilerClose,
}) => {
  const [isOpen, setOpen] = useState(true);
  const [currentHeight, setCurrentHeight] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<string>("0");
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  useEffect(() => {
    if (!contentRef?.current) return;

    setCurrentHeight(isOpen ? contentRef.current.scrollHeight : 0);
  }, [contentRef.current, isOpen, values]);

  useEffect(() => {
    if (!onSpoilerClose) return;
    onSpoilerClose();
  }, [isOpen]);

  useEffect(() => {
    const vhValue = transformValueToVH(currentHeight || 0);
    setMaxHeight(`${vhValue}`);
  }, [currentHeight]);

  const filterClasses = useMemo(
    () =>
      classNames("filter_item", {
        "filter_item--active_state": isOpen,
        "filter_item--select_open_state": selectIsOpen,
      }),
    [isOpen, selectIsOpen]
  );

  return (
    <div className={filterClasses}>
      <button
        onClick={handleClick}
        type="button"
        className="filter_item__button"
      >
        <span className="filter_item__title">{name}</span>
        <span className="filter_item__icon font_icon">
          <FiChevronDown />
        </span>
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className="filter_item__content"
      >
        <div className="filter_item__wrap">{children}</div>
      </div>
    </div>
  );
};

export default memo(FilterItem);
