import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_STORAGE } from "../../constants";
import { ICartProduct } from "../../store/orders/repository/ICartProduct";
import { getCart } from "../../store/orders/useCases/getCart/action";
import {
  selectisLoggedIn,
  selectAuthType,
} from "../../store/user/repository/selectors";
import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";
import Cart from "../../ui-kit/cart/cart";
import { fillCart } from "../../store/orders/useCases/fillCart/action";

const CartPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const authType = useSelector(selectAuthType);

  useEffect(() => {
    if (isLoggedIn !== "true") return;

    if (authType === "login") {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);
      if (!products) return;

      const parsedProducts = JSON.parse(products);
      const transformedArray = parsedProducts.map(
        ({ product, amount }: ICartProduct) => ({
          productId: product.id,
          productAmount: amount,
        })
      );
      dispatch(fillCart({ data: transformedArray }));
    } else {
      dispatch(getCart());
    }
  }, [isLoggedIn]);

  return (
    <ProfileWrapper>
      <Cart key={isLoggedIn} />
    </ProfileWrapper>
  );
};

export default CartPage;
