import { useState, useCallback, FC } from "react";
import classNames from "classnames";
import { useMedia } from "../../customHooks";
import { CLASSES } from "../../constants";
import { selectProducts, selectProductsLoading } from "../../store/products/repository/selectors";
import { useSelector } from "react-redux";
import ProductsSort from "../productsSort/productsSort";
import ProductFilters from "../productFilters/productFilters";
import ProductList from "../productList/productList";
import Section from "../section/section";
import Loader from "../loader/loader";
import { RiListSettingsLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import "./products.scss";
import { useLocation } from "react-router-dom";
import PromoProductFilters from "../promoProductFilters/promoProductFilters";
import PromoProductsSort from "../promoProductsSort/promoProductsSort";

interface IProps {
  title: string;
  description: string;
};

const Products: FC<IProps> = ({ title, description }) => {
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMedia();
  const location = useLocation();

  const { count, data } = useSelector(selectProducts);
  const isLoading = useSelector(selectProductsLoading);
  const isPromo = location.pathname.includes('promotions');

  const handleClick = useCallback(() => {
    if (isMobile) {
      document.body.classList.toggle(CLASSES.bodyScrollHide);
      setOpen((isOpen) => !isOpen);
    }
  }, [isMobile]);

  const handleClose = useCallback(() => {
    if (isMobile) {
      document.body.classList.remove(CLASSES.bodyScrollHide);
      setOpen(false);
    }
  }, [isMobile]);
  
  return (
    <Section className="products">
      {isLoading && <Loader />}
      <div className="products__body">
        <div className="products__column">
          <div className="products__head">
            <h4 className="products__title">{title}</h4>
          </div>
        </div>
        <div className="products__column">
          {isPromo ?
          <PromoProductsSort/>
          : <ProductsSort />}
        </div>
        <div className="products__column">
          {isMobile && (
            <button
              onClick={handleClick}
              type="button"
              aria-label="Open filters"
              className="products__filter_trigger font_icon"
            >
              <RiListSettingsLine />
            </button>
          )}
          <aside
            className={classNames("products__aside", {
              "products__aside--open_state": isOpen,
            })}
          >
            {isPromo ? 
            <PromoProductFilters handleClose={handleClose} isPromo={isPromo}/> 
            :
            <ProductFilters handleClose={handleClose} isPromo={isPromo}/>
            }
            {isMobile && (
              <button
                onClick={handleClick}
                type="button"
                aria-label="Close filters"
                className="products__filter_trigger products__filter_trigger--cross_mod font_icon"
              >
                <RxCross2 />
              </button>
            )}
          </aside>
        </div>
        <div className="products__column">
          <div className="products__main">
            <ProductList
              categoryProducts
              count={count}
              description={description}
              products={data}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Products;
