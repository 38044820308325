import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { setCartLoading } from "../../repository/actions";
import { IFillData } from "../../repository/IFillData";
import { getCart } from "../getCart/action";

interface IFillCartWorker {
  type: string;
  payload: IFillData;
}

function* fillCartWorker({ payload }: IFillCartWorker): SagaIterator {
  try {
    yield put(setCartLoading(true));

    const { isError, message } = yield call(
      requester.post,
      links.fillCart,
      payload,
      undefined,
      undefined,
      true
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(getCart());
    }
  } catch (error) {
    console.error("fillCartWorker: ", error);
  } finally {
    yield put(setCartLoading(false));
  }
}

export function* fillCartWatcher() {
  yield takeLatest("@saga/FILL_CART", fillCartWorker);
}
