import { IRootState } from "../../store";

export const selectUser = (state: IRootState) => state.user.user;

export const selectRegisterData = (state: IRootState) =>
  state.user.registerData;

export const selectRestoreData = (state: IRootState) => state.user.restoreData;

export const selectToken = (state: IRootState) => state.user.token;

export const selectPopupOpen = (state: IRootState) => state.user.isPopupOpen;

export const selectPasswordPopupOpen = (state: IRootState) =>
  state.user.isPasswordPopupOpen;

export const selectConfirmation = (state: IRootState) =>
  state.user.isConfirmation;

export const selectisLoggedIn = (state: IRootState) => state.user.isLoggedIn;
export const selectAuthType = (state: IRootState) => state.user.authType;
export const selectInfoPopupOpen = (state: IRootState) =>
  state.user.isInfoPopupOpen;

export const selecUpdateUserLoading = (state: IRootState) =>
  state.user.updateUserLoading;
export const selectConfirmEmailPopupOpen = (state: IRootState) =>
  state.user.confirmEmailPopupOpen;
