import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";
import ProfileSection from "../../ui-kit/profileSection/profileSection";

const ProfilePage = () => {
  return (
    <ProfileWrapper>
      <ProfileSection />
    </ProfileWrapper>
  );
};

export default ProfilePage;
