import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { PRODUCTS_PER_PAGE } from "../../constants";
import { useMedia } from "../../customHooks";
import { FiChevronDown } from "react-icons/fi";
import "./pagination.scss";

interface IProps {
  pagesCount: number;
  activeItem: number;
  onClick: (number: number) => void;
  perPage?: number;
  scrollY?: number;
}

const getFirstAndLastPage = (
  activeItem: number,
  numberOfPages: number,
  isMobile: boolean
) => {
  let firstPage = 1;
  let lastPage = numberOfPages;

  if (numberOfPages > (isMobile ? 6 : 10)) {
    if (activeItem < (isMobile ? 4 : 10)) {
      lastPage = isMobile ? 6 : 10;
    } else if (activeItem > numberOfPages - (isMobile ? 2 : 5)) {
      firstPage = numberOfPages - (isMobile ? 5 : 9);
    } else {
      firstPage = activeItem - (isMobile ? 3 : 4);
      lastPage = activeItem + (isMobile ? 2 : 5);
    }
  }

  return { firstPage, lastPage };
};

const Pagination: FC<IProps> = ({
  pagesCount,
  activeItem,
  perPage = PRODUCTS_PER_PAGE,
  onClick,
  scrollY = 0,
}) => {
  const [numberOfPages, setNumberOfPage] = useState(
    Math.ceil(pagesCount / perPage)
  );
  const isMobile = useMedia();

  useEffect(() => {
    setNumberOfPage(Math.ceil(pagesCount / perPage));
  }, [pagesCount, perPage]);

  if (numberOfPages <= 1) return null;

  const { firstPage, lastPage } = getFirstAndLastPage(
    activeItem,
    numberOfPages,
    isMobile
  );
  const pageNumbers = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1
  );
  const pageButtons = pageNumbers.slice(firstPage - 1, lastPage);

  const handleClick = (number: number) => {
    onClick(number);
    window.scrollTo({ top: scrollY, behavior: "smooth" });
  };

  return (
    <div className="pagination">
      <button
        aria-label="Prev"
        className={classNames(
          "pagination__arrow",
          "pagination__arrow--prev_mod",
          {
            "pagination__arrow--disabled_mod": activeItem === 1,
          }
        )}
        onClick={() => handleClick(activeItem - 1)}
      >
        <span>
          <FiChevronDown />
        </span>
      </button>
      <ul className="pagination__list">
        {firstPage > 1 && (
          <li key={1} className="pagination__item">
            <div className="pagination__dotts">...</div>
          </li>
        )}
        {pageButtons.map((number) => (
          <li key={number} className="pagination__item">
            <button
              onClick={() => handleClick(number)}
              type="button"
              className={classNames("pagination__button", {
                "pagination__button--active_state": activeItem === number,
              })}
            >
              {number}
            </button>
          </li>
        ))}
        {lastPage < numberOfPages && (
          <li key={numberOfPages} className="pagination__item">
            <div className="pagination__dotts">...</div>
          </li>
        )}
      </ul>
      <button
        aria-label="Next"
        className={classNames(
          "pagination__arrow",
          "pagination__arrow--next_mod",
          {
            "pagination__arrow--disabled_mod": activeItem === numberOfPages,
          }
        )}
        onClick={() => handleClick(activeItem + 1)}
      >
        <span>
          <FiChevronDown />
        </span>
      </button>
    </div>
  );
};

export default Pagination;
