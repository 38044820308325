import { FC } from "react";
import { INewsColumn } from "../../../store/news/repository/INews";
import SanitaizedText from "../../sanitaizedText/sanitaizedText";
import Image from "../../image/image";

interface IProps {
  main?: INewsColumn;
}

const SingleColumn: FC<IProps> = ({ main }) => {
  return (
    <div className="single_news_content__column">
      {main?.type === "TEXT" && (
        <SanitaizedText
          className="single_news_content__text"
          text={main?.value}
        />
      )}
      {main?.type === "IMAGE" && (
        <div className="single_news_content__image">
          <Image src={main?.value} />
        </div>
      )}
    </div>
  );
};

export default SingleColumn;
