import { FC } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import i18n from "../../locales";
import { addRating } from "../../store/news/useCases/addRating/action";
import { selectRatingPopupOpen } from "../../store/news/repository/selectors";
import { setRatingPopupOpen } from "../../store/news/repository/actions";
import Popup from "../popup/popup";
import MarkRating from "../markRating/markRating";
import "./ratePopup.scss";

interface IRatingData {
  rating: number;
}

interface IProps {
  newsId: number | null;
  setNewsId: (id: number | null) => void;
}

const RatePopup: FC<IProps> = ({ newsId, setNewsId }) => {
  const { handleSubmit, setValue } = useForm<IRatingData>();
  const dispatch = useDispatch();
  const isOpen = useSelector(selectRatingPopupOpen);

  const onSumbit = ({ rating }: IRatingData) => {
    if (newsId) dispatch(addRating({ newsId, rating }));
  };

  const handleClose = () => {
    dispatch(setRatingPopupOpen(false));
    setNewsId(null);
  };

  return (
    <Popup size="xxs" isOpen={isOpen} handleClose={handleClose}>
      <form
        onSubmit={handleSubmit(onSumbit)}
        action="#"
        className="rate_popup__form"
      >
        <div className="rate_popup__title">{i18n.t("setArticleRating")}</div>
        <div className="rate_popup__rating">
          <MarkRating required setValue={setValue} />
        </div>
        <button type="submit" className="rate_popup__button">
          <span>{i18n.t("send")}</span>
        </button>
      </form>
    </Popup>
  );
};

export default RatePopup;
