import { FC } from "react";
import classNames from "classnames";
import { gsap } from "gsap";
import { useDispatch } from "react-redux";
import { useRef, useEffect, useState } from "react";
import { setNotificationsRead } from "../../../store/notifications/useCases/setNotificationsRead/action";
import { transformDate } from "../../../utils/transformDate";
import { INotification } from "../../../store/notifications/repository/INotification";
import Image from "../../image/image";
import SanitaizedText from "../../sanitaizedText/sanitaizedText";
import { FiChevronDown } from "react-icons/fi";
import "./notificationItem.scss";

const NotificationItem: FC<INotification> = ({
  id,
  title,
  image_url,
  created_at,
  text,
  status,
}) => {
  const [isActive, setActive] = useState(false);
  const $content = useRef<HTMLDivElement | null>(null);
  const [isRead, setRead] = useState(status === "Read");

  const dispatch = useDispatch();

  const handleClick = () => {
    if (!isActive && !isRead) {
      setRead(true);
      dispatch(setNotificationsRead({ data: [{ id }] }));
    }

    setActive((prev) => !prev);
  };

  useEffect(() => {
    const target = $content.current;

    if (isActive) {
      gsap.to(target, {
        height: "auto",
        duration: 0.3,
      });

      return () => {
        gsap.to(target, {
          height: "0",
          duration: 0.3,
        });
      };
    }
    return () => {};
  }, [id, isActive]);

  useEffect(() => {
    setRead(status === "Read");
  }, [status]);

  return (
    <div
      className={classNames("notification_item", {
        "notification_item--active_state": isActive,
        "notification_item--read_state": isRead,
      })}
    >
      <button
        type="button"
        className="notification_item__button"
        onClick={handleClick}
      >
        <Image
          imgClass="notification_item__image"
          src={image_url || "./logo.png"}
          alt={title}
        />
        <div className="notification_item__main">
          <div className="notification_item__title">{title}</div>
          <div className="notification_item__arrow font_icon">
            <FiChevronDown />
          </div>
        </div>
        <div className="notification_item__wrap">
          <div className="notification_item__status" />
          <div className="notification_item__date">
            {transformDate(created_at, true)}
          </div>
        </div>
      </button>
      <div ref={$content} className="notification_item__content">
        <SanitaizedText className="notification_item__footer" text={text} />
      </div>
    </div>
  );
};

export default NotificationItem;
