import { IUpdateUser } from "../../repository/IUpdateUser";

export const updateUser = (
  payload: IUpdateUser,
  showMessage?: boolean,
  isEmailChange?: boolean
) => ({
  type: "@saga/UPDATE_USER",
  payload: { ...payload, showMessage, isEmailChange },
});
