import i18n from "../../locales";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabPanel } from "react-tabs";
import Loader from "../loader/loader";
import Section from "../section/section";
import Characteristics from "../characteristics/characteristics";
import ImagesDescriptions from "../imagesDescriptions/imagesDescriptions";
import ProductDescription from "../productDescription/productDescription";
import ProductSlider from "../singleProductSlider/singleProductSlider";
import Reviews from "../reviews/reviews";
import ReviewsForm from "../reviewsForm/reviewsForm";
import SingleProductTabs from "../singleProductTabs/singleProductTabs";
import { selectProductReviews } from "../../store/reviews/repository/selectors";
import { selectProductCharacteristics, selectProductCharacteristicsLoading, selectProductDescription, selectSingleProduct, selectSingleProductLoading, } from "../../store/products/repository/selectors";
import { InfoItem } from "./ui";
import { selectProductReviewsPage } from "../../store/reviews/repository/selectors";
import { getProductCharacteristics } from "../../store/products/useCases/getCharacteristics/action";
import { getProductReviews } from "../../store/reviews/useCases/getProductReviews/action";
import "./singleProduct.scss";

const SingleProduct = () => {
  const product = useSelector(selectSingleProduct);
  const characteristics = useSelector(selectProductCharacteristics);
  const reviews = useSelector(selectProductReviews);
  const descriptions = useSelector(selectProductDescription);
  const isLoading = useSelector(selectSingleProductLoading);
  const isCharacteristicsLoading = useSelector(selectProductCharacteristicsLoading);
  const currentPage = useSelector(selectProductReviewsPage);
  const dispatch = useDispatch();

  const [hasFetchedCharacteristics, setHasFetchedCharacteristics] = useState<boolean>(false);
  const [hasFetchedReviews, setHasFetchedReviews] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);
  const headRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headRef.current) {
      const headerElement = document.querySelector(".header") as HTMLElement;
      let headerHeight = 0;

      const { top } = headRef.current.getBoundingClientRect();

      if (headerElement) {
        headerHeight = headerElement.offsetHeight;
      }

      setScrollY(top - headerHeight);
    }
  }, [headRef, isLoading]);

  const hasDescription = descriptions.length > 0;

  const handleOpenTab = (index: number) => {
    if (hasDescription) {
      if (index === 1 && product && !hasFetchedCharacteristics) {
        dispatch(getProductCharacteristics(product.id.toString()));
        setHasFetchedCharacteristics(true);
      }

      if (index === 2 && product && !hasFetchedReviews) {
        dispatch(getProductReviews(product.id, currentPage));
        setHasFetchedReviews(true);
      }
    } else {
      if (index === 1 && product && !hasFetchedReviews) {
        dispatch(getProductReviews(product.id, currentPage));
        setHasFetchedReviews(true);
      }
    }
  };

  useEffect(() => {
    if (!product || hasDescription) return;

    dispatch(getProductCharacteristics(product.id.toString()));
    setHasFetchedCharacteristics(true);
  }, [hasDescription, product?.id]);

  return (
    <Section noMaxWidth className="single_product">
      {isLoading && <Loader />}
      {product && (
        <>
          <div className="single_product__wrap">
            <div className="section_in">
              <div className="single_product__row">
                <div className="single_product__column">
                  {product?.images?.length > 0 && (
                    <ProductSlider
                      videoId={product.video}
                      images={product.images}
                    />
                  )}
                </div>
                <div className="single_product__column">
                  <ProductDescription productData={product} />
                </div>
              </div>
            </div>
          </div>
          <div className="single_product__wrap">
            {product.saleComment && (
              <InfoItem
                mod="sale"
                label={i18n.t("sale")}
                text={product.saleComment}
              />
            )}
            {product.promo && (
              <InfoItem
                mod="promo"
                label={i18n.t("promo")}
                title={product.promo.title}
                text={product.promo.description}
              />
            )}
          </div>
          <div ref={headRef} className="single_product__block">
            <SingleProductTabs
              hasDescription={hasDescription}
              onSelect={handleOpenTab}
            >
              {hasDescription && (
                <TabPanel>
                  <ImagesDescriptions descriptions={descriptions} />
                </TabPanel>
              )}
              <TabPanel>
                <div className="single_product__tab">
                  <h3 className="single_product__title">
                    {i18n.t("characteristics")}
                  </h3>
                  {isCharacteristicsLoading && <Loader isLocal />}
                  {characteristics && (
                    <Characteristics data={characteristics} />
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="single_product__tab">
                  <div className="single_product__wrap">
                    {reviews && (
                      <Reviews
                        productId={product.id}
                        reviews={reviews}
                        title={i18n.t("reviews")}
                        average={product.rating}
                        scrollY={scrollY}
                      />
                    )}
                  </div>
                  <div id="write_review_form" className="single_product__wrap">
                    <h3 className="single_product__title">
                      {i18n.t("leaveFeedback")}
                    </h3>
                    <ReviewsForm />
                  </div>
                </div>
              </TabPanel>
            </SingleProductTabs>
          </div>
        </>
      )}
    </Section>
  );
};

export default SingleProduct;
