import { useEffect } from "react";
import FavoritesSection from "../../ui-kit/favoritesSection/favoritesSection";
import { useDispatch, useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectFavoritesPage } from "../../store/favorites/repository/selectors";
import { getFavoritesProducts } from "../../store/favorites/useCases/getFavorites/action";
import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";

const FavoritesPage = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectFavoritesPage);
  const isLoggedIn = useSelector(selectisLoggedIn);

  useEffect(() => {
    isLoggedIn === "true" && dispatch(getFavoritesProducts(currentPage));
  }, [currentPage, isLoggedIn]);

  return (
    <ProfileWrapper>
      <FavoritesSection />
    </ProfileWrapper>
  );
};

export default FavoritesPage;
