import { Store } from "react-notifications-component";

interface INotificationOptions {
  title: string;
  message: string;
  type: "success" | "danger" | "info" | "default" | "warning";
  duration?: number;
}

export const createNotification = ({
  title,
  message,
  type,
  duration,
}: INotificationOptions): void => {
  Store.addNotification({
    title,
    message,
    type,
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration ?? 3000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
  });
};
