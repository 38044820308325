import { useState, FC, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import MegaCatalog from "../megaCatalog/megaCatalog";
import { CLASSES, ROUTES } from "../../constants";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { ICatalogHistory } from "../../store/catalog/repository/ICatalogHistory";
import { IconType } from "react-icons/lib";
import { saveCatalogHistory } from "../../store/catalog/repository/actions";
import { selectCatalogHistory } from "../../store/catalog/repository/selectors";
import "./megaItem.scss";

interface IProps {
  item: ICatalog;
  Icon?: IconType;
  catalogRef?: RefObject<HTMLDivElement>;
}

const MegaItem: FC<IProps> = ({ item, Icon, catalogRef }) => {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(-2);

  const dispatch = useDispatch();
  const catalogHistoryList: ICatalogHistory[] =
    useSelector(selectCatalogHistory);

  const historyLength = catalogHistoryList.length - 1;
  const isCurrentCatalog = catalogHistoryList[historyLength];

  // close all catalogs if click on the burger
  if (!document.body.classList.contains(CLASSES.menuOpen) && open) {
    setOpen(false);
  }

  // hide menu scroll when mega catalog is open
  isCurrentCatalog !== undefined
    ? document.body.classList.add(CLASSES.megamenuOpen)
    : document.body.classList.remove(CLASSES.megamenuOpen);

  const handleClick = () => {
    setOpen(true);

    const history = [...catalogHistoryList, { item }];
    dispatch(saveCatalogHistory(history));

    setCurrent(item.id);
    catalogRef && catalogRef.current?.scroll(0, 0);
  };

  const onClose = () => {
    setOpen(false);
    const history = [...catalogHistoryList].slice(0, historyLength);
    dispatch(saveCatalogHistory(history));

    const value = history.length ? history[history.length - 1].item.id : -1;
    setCurrent(value);
  };

  const onClickLink = () => {
    document.body.classList.remove(CLASSES.menuOpen);
    dispatch(saveCatalogHistory([]));
  };

  const { id, name, childCategories } = item;

  return (
    <div className="mega_item">
      {childCategories?.length ? (
        <div className="mega_item__wrap">
          <button
            type="button"
            onClick={handleClick}
            className="mega_item__button"
          >
            {Icon && (
              <span className="mega_item__icon font_icon">
                <Icon />
              </span>
            )}
            <span className="mega_item__text">{name}</span>
            <span className="mega_item__arrow font_icon">
              <FiChevronDown />
            </span>
          </button>
          <MegaCatalog
            childCategories={childCategories}
            name={name}
            id={id}
            onClickLink={onClickLink}
            open={open}
            onClose={onClose}
            currentId={current}
          />
        </div>
      ) : (
        <Link
          to={`${ROUTES.ProductList}/${item.id}`}
          className="mega_item__link"
          onClick={onClickLink}
        >
          <span className="mega_item__text">{name}</span>
        </Link>
      )}
    </div>
  );
};

export default MegaItem;
