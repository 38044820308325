import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { saveWaitingList } from "../../repository/actions";
import { setAppLoading } from "../../../app/repository/actions";
import { PRODUCTS_PER_PAGE } from "../../../../constants";

interface IGetWaitingListWorker {
  type: string;
  payload: {
    page: number;
    noLoading?: boolean;
  };
}

function* getWaitingListWorker({
  payload,
}: IGetWaitingListWorker): SagaIterator {
  try {
    if (!payload.noLoading) yield put(setAppLoading(true));
    const params = {
      page: payload.page ?? 1,
      perPage: PRODUCTS_PER_PAGE,
    };

    const { data, isError, message } = yield call(
      requester.get,
      links.getWaitingList,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveWaitingList(data));
    }
  } catch (error) {
    console.error("getWaitingListWorker: ", error);
  } finally {
    if (!payload.noLoading) yield put(setAppLoading(false));
  }
}

export function* getWaitingListWatcher() {
  yield takeLatest("@saga/GET_WAITING_LIST", getWaitingListWorker);
}
