import i18n from "../../locales";
import { useDispatch } from "react-redux";
import { setAuthPopupOpen } from "../../store/user/repository/actions";
import "./unregisteredMessage.scss";

const UnregisteredMessage = () => {
  const dispatch = useDispatch();

  const handlePopupOpen = () => {
    dispatch(setAuthPopupOpen(true));
  };

  return (
    <div className="unregistered_message">
      <h3 className="unregistered_message__title">
        {i18n.t("loginOrRegisterTitle")}
      </h3>
      <button
        className="unregistered_message__button"
        type="button"
        onClick={handlePopupOpen}
      >
        {i18n.t("loginOrRegister")}
      </button>
    </div>
  );
};

export default UnregisteredMessage;
