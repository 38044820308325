import { useState, useEffect, FC, ChangeEvent } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentSettings } from "../../store/products/repository/selectors";
import Slider from "rc-slider";
import Input from "../input/input";

import "./priceSlider.scss";
import "./slider.scss";

interface IProps {
  min: number;
  max: number;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  onChange?: () => void;
}
type TValue = number | number[];

const PriceSlider: FC<IProps> = ({
  onChange,
  min,
  max,
  register,
  setValue,
}) => {
  const [value, setValueLocal] = useState<TValue>([min, max]);
  const currentSettings = useSelector(selectCurrentSettings);

  useEffect(() => {
    if (!currentSettings?.priceMax || !currentSettings?.priceMin) return;

    setTimeout(() => {
      if (currentSettings.priceMin && currentSettings.priceMax)
        setValueLocal([currentSettings.priceMin, currentSettings.priceMax]);
    }, 10);
  }, []);

  useEffect(() => {
    handleChange([min, max], true);
  }, [min, max]);

  const handleChange = (newValue: TValue, noFilter?: boolean): void => {
    if (typeof newValue !== "number") {
      if (
        Array.isArray(value) &&
        newValue[0] === value[0] &&
        newValue[1] === value[1]
      )
        return;

      setValueLocal(newValue);
      setValue("min", newValue[0]);
      setValue("max", newValue[1]);

      if (onChange && !noFilter) onChange();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.name;
    const inputValue = e.target.value.trim();

    if (typeof value === "number") return;

    const newValue = parseInt(inputValue, 10);

    if (type === "max") {
      setValueLocal([value[0], newValue]);
    }

    if (type === "min") {
      setValueLocal([newValue, value[1]]);
    }

    onChange && onChange();
  };

  const validateInputValue = (value: TValue, type: 0 | 1) => {
    if (typeof value !== "number") {
      if (value[type] !== undefined && typeof value[type] === "number") {
        return value[type];
      } else {
        return "";
      }
    }

    return value;
  };

  const validatePriceInput = (value: TValue) =>
    Array.isArray(value) ? [value[0] || min, value[1] || max] : value;

  const unionInputProps = {
    className: "price_slider__input",
    type: "number",
    min,
    max,
  };

  const priceSliderProps = {
    min: min,
    max: max,
    value: validatePriceInput(value),
    onChange: handleChange,
    range: true,
    step: 10,
  };

  return (
    <div className="price_slider">
      <div className="price_slider__wrap">
        <div className="price_slider__item price_slider__item--input_mod">
          <Input
            {...unionInputProps}
            inputMode="numeric"
            register={register("min")}
            title="Min price"
            value={validateInputValue(value, 0)}
            onChange={handleInputChange}
          />
        </div>
        <div className="price_slider__item">-</div>
        <div className="price_slider__item price_slider__item--input_mod">
          <Input
            {...unionInputProps}
            inputMode="numeric"
            register={register("max")}
            title="Max price"
            value={validateInputValue(value, 1)}
            onChange={handleInputChange}
          />
        </div>
        <div className="price_slider__item">грн.</div>
      </div>
      <div className="price_slider__range">
        <Slider {...priceSliderProps} />
      </div>
    </div>
  );
};

export default PriceSlider;
