import i18n from "../../locales";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectCart,
  selectDislaimerPopupOpen,
} from "../../store/orders/repository/selectors";
import Section from "../section/section";
import OrderProduct from "../orderProduct/orderProduct";
import OrderingForm from "./ui/orderingForm/orderingForm";
import Price from "../price/price";
import "./orderingSection.scss";

const OrderingSection = () => {
  const {
    data,
    totalPriceGRN,
    totalPriceYE,
    oldTotalPriceGRN,
    oldTotalPriceYE,
    promoCode,
  } = useSelector(selectCart);
  const isConfirmPopupOpen = useSelector(selectDislaimerPopupOpen);

  const [realPriceGrn, setRealPriceGrn] = useState<string>(totalPriceGRN);
  const [realPriceYe, setRealPriceYe] = useState<string>(totalPriceYE);
  const [cardVisible, setCartVisible] = useState(true);

  useEffect(() => {
    if ((data && data.length > 0) || isConfirmPopupOpen) {
      setCartVisible(true);
    } else {
      setCartVisible(false);
    }
  }, [data]);

  return (
    <Section className="ordering_section">
      {cardVisible ? (
        <>
          {data.length > 0 && (
            <div className="ordering_section__wrap">
              <ul className="ordering_section__list">
                {data.map(({ product, amount }) => {
                  return (
                    <li key={product.id} className="ordering_section__item">
                      <OrderProduct count={amount} {...product} />
                    </li>
                  );
                })}
              </ul>
              <div className="ordering_section__row">
                <div className="ordering_section__promo">
                  {promoCode?.promCode && (
                    <div className="ordering_section__amount">
                      <div className="ordering_section__label">
                        {i18n.t("promoCode")}:
                      </div>
                      {promoCode.promCode}
                    </div>
                  )}
                </div>
                <div className="ordering_section__bottom">
                  {Number(realPriceGrn) !== 0 && (
                    <div className="ordering_section__amount">
                      <div className="ordering_section__label">
                        {i18n.t("totalSum")}:
                      </div>
                      <div className="ordering_section__value">
                        <Price
                          price={realPriceGrn}
                          oldPrice={oldTotalPriceGRN}
                        />
                      </div>
                    </div>
                  )}
                  {Number(realPriceYe) !== 0 && (
                    <div className="ordering_section__amount">
                      <div className="ordering_section__label">
                        {i18n.t("totalSum")}:
                      </div>
                      <div className="ordering_section__value">
                        <Price
                          currency="ye"
                          price={realPriceYe}
                          oldPrice={oldTotalPriceYE}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="ordering_section__wrap">
            <OrderingForm
              realPriceGrn={realPriceGrn}
              realPriceYe={realPriceYe}
              totalPriceGRN={totalPriceGRN}
              totalPriceYE={totalPriceYE}
              setRealPriceGrn={setRealPriceGrn}
              setRealPriceYe={setRealPriceYe}
            />
          </div>
        </>
      ) : (
        <div className="cart__message">
          <p>{i18n.t("emptyCart1")}</p>
          <p>{i18n.t("emptyCart2")}</p>
        </div>
      )}
    </Section>
  );
};

export default OrderingSection;
