import i18n from "../../locales";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProducts } from "../../store/products/repository/selectors";
import { selectProductsLoading } from "../../store/products/repository/selectors";
import Section from "../section/section";
import Loader from "../loader/loader";
import ProductList from "../productList/productList";
import ProductsSort from "../productsSort/productsSort";
import "./searchResult.scss";
import classNames from "classnames";

const SearchResult = () => {
  const { count, data } = useSelector(selectProducts);
  const isLoading = useSelector(selectProductsLoading);
  const { name } = useParams();

  return (
    <Section
      className={classNames("search_result", {
        "search_result--no_result": data.length === 0,
      })}
    >
      {isLoading && <Loader />}
      {data.length ? (
        <>
          <div className="search_result__head">
            <h3 className="search_result__title">
              {i18n.t("queryResult")}: "{name}"
            </h3>

            <ProductsSort searchMod />
          </div>
          <ProductList searchMod count={count} products={data} />
        </>
      ) : (
        <div className="search_result__placeholder">
          <h3 className="search_result__title">
            {i18n.t("queryNoResult")}: "{name}"
          </h3>
          <div className="search_result__text">
            <p>{i18n.t("noResultMessage1")}</p>
            <p>{i18n.t("noResultMessage2")}</p>
          </div>
        </div>
      )}
    </Section>
  );
};
export default SearchResult;
