import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../store/orders/useCases/getOrders/action";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";
import OrdersSection from "../../ui-kit/ordersSection/ordersSection";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== "true") return;

    dispatch(getOrders(1, "Pending"));
  }, [isLoggedIn]);

  return (
    <ProfileWrapper>
      <OrdersSection />
    </ProfileWrapper>
  );
};

export default OrdersPage;
