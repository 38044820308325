import { IUser } from "./IUser";
import { IRegisterData } from "./IRegisterData";
import { IRestorePassData } from "./IRestorePassData";
import { TAuthType } from "../../../types/authType";

export const saveUser = (payload: IUser) => ({
  type: "SAVE_USER",
  payload,
});

export const saveRegisterData = (payload: IRegisterData | null) => ({
  type: "SAVE_REGISTER_DATA",
  payload,
});

export const saveRestoreData = (payload: IRestorePassData | null) => ({
  type: "SAVE_RESTORE_DATA",
  payload,
});

export const saveToken = (payload: string) => ({
  type: "SAVE_TOKEN",
  payload,
});

export const setAuthPopupOpen = (payload: boolean) => ({
  type: "SET_AUTH_POPUP_OPEN",
  payload,
});

export const setPasswordPopupOpen = (payload: boolean) => ({
  type: "SET_PASSWORD_POPUP_OPEN",
  payload,
});

export const setConfirmation = (payload: boolean) => ({
  type: "SET_CONFIRMATION",
  payload,
});

export const setLoggedIn = (payload: string | null) => ({
  type: "SET_LOGGED_IN",
  payload,
});

export const setAuthType = (payload: TAuthType | null) => ({
  type: "SET_AUTH_TYPE",
  payload,
});

export const setInfoPopupOpen = (payload: boolean) => ({
  type: "SET_INFO_POPUP_OPEN",
  payload,
});

export const setUpdateUserLoading = (payload: boolean) => ({
  type: "SET_UPDATE_USER_LOADING",
  payload,
});

export const setConfirmEmailPopupOpen = (payload: boolean) => ({
  type: "SET_CONFIRM_EMAIL_POPUP_OPEN",
  payload,
});
