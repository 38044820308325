import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveCart, setAddToOrderLoading, saveAddToOrderLoadingId } from "../../repository/actions";

interface IAddProductToOrderWorker {
  type: string;
  payload: {
    productId: number;
    productAmount?: number;
  };
};

function* addProductToOrderWorker({ payload }: IAddProductToOrderWorker): SagaIterator {
  try {
    yield put(setAddToOrderLoading(true));
    yield put(saveAddToOrderLoadingId(payload.productId));

    const { data, isError, message } = yield call(requester.post, links.addProductToOrder, { productId: payload.productId, productAmount: payload.productAmount || 1 }, undefined, undefined, true);
    if (isError) {
      createNotification({ title: i18n.t("error"), message, type: "danger" });
    } else if (data && data.message === "product_already_in_order") {
      createNotification({ title: i18n.t("error"), message: i18n.t("alreadyInCart"), type: "danger" });
    } else {
      if (!data) return;
      // createNotification({
      //   title: i18n.t("successAddedToOrder"),
      //   message,
      //   type: "success",
      // });
      yield put(saveCart(data));
    }
  } catch (error) {
    console.error("addProductToOrderWorker: ", error);
  } finally {
    yield put(setAddToOrderLoading(false));
    yield put(saveAddToOrderLoadingId(null));
  }
};

export function* addProductToOrderWatcher() {
  yield takeLatest("@saga/ADD_PRODUCT_TO_ORDER", addProductToOrderWorker);
};
