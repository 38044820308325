import { FC, ReactNode } from "react";
import CustomSelect, { IProps } from "../customSelect/customSelect";
import Label from "../label/label";
import "./selectField.scss";

interface IFieldProps extends IProps {
  id: string;
  label: ReactNode;
  required?: boolean;
  isError?: boolean;
}

const SelectField: FC<IFieldProps> = ({ id, label, required, ...props }) => {
  return (
    <div className="select_field">
      <Label id={id} required={required}>
        {label}
      </Label>
      <CustomSelect id={id} {...props} />
    </div>
  );
};

export default SelectField;
