import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  saveProductsFile,
  setProductsFileLoading,
} from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

function* getProductsFileWorker(): SagaIterator {
  try {
    yield put(setProductsFileLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      links.getProductsFile
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveProductsFile(data));
    }
  } catch (error) {
    console.error("getProductsFileWorker: ", error);
  } finally {
    yield put(setProductsFileLoading(false));
  }
}

export function* getProductsFileWatcher() {
  yield takeLatest("@saga/GET_PRODUCTS_FILE", getProductsFileWorker);
}
