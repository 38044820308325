import { IRootState } from "../../store";

export const selectOrders = (state: IRootState) => state.orders.orders;
export const selectOrdersPage = (state: IRootState) => state.orders.ordersPage;
export const selectOrdersLoading = (state: IRootState) =>
  state.orders.ordersLoading;
export const selectDislaimerPopupOpen = (state: IRootState) =>
  state.orders.disclaimerPopupOpen;

export const selectCart = (state: IRootState) => state.orders.cart;
export const selectCartChange = (state: IRootState) => state.orders.cartChange;
export const selectCartLoading = (state: IRootState) =>
  state.orders.cartLoading;

export const selectAddToOrderLoading = (state: IRootState) =>
  state.orders.addToOrderLoading;
export const selectAddToOrderLoadingId = (state: IRootState) =>
  state.orders.addToOrderLoadingId;

export const selectCurrentOrder = (state: IRootState) =>
  state.orders.currentOrder;
export const selectFactPaynamentMethod = (state: IRootState) =>
  state.orders.isPaynamentMethodFact;
