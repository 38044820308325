import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  saveProductReviews,
  setProductReviewsLoading,
} from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { REVIEWS_PER_PAGE } from "../../../../constants";

interface IGetProductReviewsWorker {
  type: string;
  payload: {
    id: number;
    page: number;
  };
}

function* getProductReviewsWorker({
  payload,
}: IGetProductReviewsWorker): SagaIterator {
  try {
    const params = {
      perPage: REVIEWS_PER_PAGE,
      page: payload.page,
    };

    yield put(setProductReviewsLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      `${links.productReviews}/${payload.id}`,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveProductReviews(data));
    }
  } catch (error) {
    console.error("getProductReviewsWorker: ", error);
  } finally {
    yield put(setProductReviewsLoading(false));
  }
}

export function* getProductReviewsWatcher() {
  yield takeLatest("@saga/GET_PRODUCT_REVIEWS", getProductReviewsWorker);
}
