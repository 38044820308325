import { useEffect } from "react";
import { CgDarkMode } from "react-icons/cg";
import { LOCAL_STORAGE } from "../../constants";
import "./themeButton.scss";

const { theme, darkTheme, lightTheme } = LOCAL_STORAGE;

const ThemeButton = () => {
  const switchTheme = (isDark: boolean) =>
    isDark ? setDarkTheme() : setLightTheme();

  const setDarkTheme = () => {
    document.body.setAttribute(theme, darkTheme);
    localStorage.setItem(theme, darkTheme);
  };

  const setLightTheme = () => {
    document.body.setAttribute(theme, lightTheme);
    localStorage.setItem(theme, lightTheme);
  };

  const handleClick = () => {
    switchTheme(document.body.getAttribute(theme) === lightTheme);
  };

  // set default browser theme if it is not defined
  const storageThemeField = localStorage.getItem(theme);
  const defaultDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");

  if (storageThemeField !== null) {
    switchTheme(storageThemeField === darkTheme);
  } else {
    switchTheme(defaultDarkTheme.matches);
  }

  useEffect(() => {
    defaultDarkTheme.addEventListener("change", (event) => {
      switchTheme(event.matches);
    });

    return () => {
      defaultDarkTheme.removeEventListener("change", (event) => {
        switchTheme(event.matches);
      });
    };
  }, []);

  return (
    <button
      type="button"
      aria-label="Change theme"
      onClick={handleClick}
      className="theme_button"
    >
      <div className="theme_button__icon font_icon">
        <CgDarkMode />
      </div>
    </button>
  );
};

export default ThemeButton;
