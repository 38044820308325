import { ComponentType } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLang } from "../store/language/repository/selectors";

const withLanguage = (Component: ComponentType) => {
  const LanguageWrapper = () => {
    const currentLang = useSelector(selectCurrentLang);
    document.documentElement.lang = currentLang;

    return <Component key={currentLang} />;
  };

  return LanguageWrapper;
};

export default withLanguage;
