import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSaleProducts } from "../../store/products/useCases/getSaleProducts/action";
import { getGroupProducts } from "../../store/groupProducts/useCases/getGroupList/action";
import { getPromoProducts } from "../../store/promo/useCases/getPromoProducts/action";
import { getBanners } from "../../store/banners/useCases/getBanners/action";
import FullScreenSlider from "../../ui-kit/fullScreenSlider/fullScreenSlider";
import GroupProducts from "../../ui-kit/groupProducts/groupProducts";
import SaleProducts from "../../ui-kit/saleProducts/saleProducts";
import PromoProducts from "../../ui-kit/promoProducts/promoProducts";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanners());
    dispatch(getSaleProducts());
    dispatch(getPromoProducts());
    dispatch(getGroupProducts());
  }, []);

  return (
    <>
      <FullScreenSlider />
      <SaleProducts />
      {/* <PromoProducts /> */}
      <GroupProducts />
    </>
  );
};

export default HomePage;
