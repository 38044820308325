import { IPriceListResponse } from "../../excel/repository/IPriceListResponse";

export const saveProductsFile = (payload: IPriceListResponse) => ({
  type: "SAVE_PRODUCTS_FILE",
  payload,
});

export const setProductsFileLoading = (payload: boolean) => ({
  type: "SET_PRODUCTS_FILE_LOADING",
  payload,
});
