import { FC, useRef, useMemo } from "react";
import { IGroupCategory } from "../../store/groupProducts/repository/IGroupCategory";
import { IProduct } from "../../store/products/repository/IProduct";
import i18n from "../../locales";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TSliderMod } from "../../types/sliderMod";
import useDraggableScroll from "use-draggable-scroll";
import Section from "../section/section";
import ProductsSlider from "../productsSlider/productsSlider";
import Loader from "../loader/loader";
import "./productsTabs.scss";

interface IProps {
  products: IProduct[];
  title: string;
  categories: IGroupCategory[];
  handleTabClick: (id: number) => void;
  mod: TSliderMod;
  isLoading?: boolean;
  groupId?: number;
}

const ProductsTabs: FC<IProps> = ({
  products,
  categories,
  title,
  isLoading = false,
  handleTabClick,
  mod,
  groupId,
}) => {
  const scrollRef = useRef(null);
  const { onMouseDown } = useDraggableScroll(scrollRef, {
    direction: "horizontal",
  });

  const onTabClick = (id: number) => {
    handleTabClick(id);
  };

  const sortedCategories = useMemo(
    () => [...categories].sort((a, b) => a.sortNumber - b.sortNumber),
    [categories]
  );

  if (!categories.length) return null;

  return (
    <Section className="products_tabs">
      {isLoading && <Loader />}
      <div className="products_tabs__head">
        <h3 className="products_tabs__title">{title}</h3>
      </div>
      <Tabs>
        <div className="products_tabs__wrap">
          <nav
            ref={scrollRef}
            onMouseDown={onMouseDown}
            className="products_tabs__nav"
          >
            <TabList className="products_tabs__list">
              {categories.length > 1 && (
                <Tab
                  onClick={() => onTabClick(-1)}
                  key={title}
                  className="products_tabs__item"
                >
                  <button type="button" className="products_tabs__button">
                    {i18n.t("allProducts")}
                  </button>
                </Tab>
              )}
              {sortedCategories.map(({ id, name }) => {
                return (
                  <Tab
                    onClick={() => onTabClick(id)}
                    key={id}
                    className="products_tabs__item"
                  >
                    <button type="button" className="products_tabs__button">
                      {name}
                    </button>
                  </Tab>
                );
              })}
            </TabList>
          </nav>
        </div>

        {categories.length > 1 && (
          <TabPanel key={title}>
            <ProductsSlider
              groupId={groupId}
              mod={mod}
              id={-1}
              products={products}
            />
          </TabPanel>
        )}
        {sortedCategories.map(({ id }) => {
          return (
            <TabPanel key={id}>
              <ProductsSlider
                groupId={groupId}
                mod={mod}
                id={id}
                products={products}
              />
            </TabPanel>
          );
        })}
      </Tabs>
    </Section>
  );
};

export default ProductsTabs;
