import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  selectCart,
  selectCartChange,
} from "../../store/orders/repository/selectors";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { getCart } from "../../store/orders/useCases/getCart/action";
import { LOCAL_STORAGE, ROUTES } from "../../constants";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ImCart } from "react-icons/im";
import "./cardButton.scss";

const CartButton = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectCart);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const cartChange = useSelector(selectCartChange);

  const { pathname } = useLocation();
  const [count, setCount] = useState<number>(data?.length ?? 0);

  const handleStorageChange = () => {
    const products = localStorage.getItem(LOCAL_STORAGE.cart);
    if (products) {
      setCount(JSON.parse(products).length);
    } else {
      setCount(0);
    }
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      dispatch(getCart());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn === "true") {
      setCount(data?.length ?? 0);
    } else {
      handleStorageChange();
    }
  }, [data, cartChange, isLoggedIn]);

  return (
    <Link
      to={ROUTES.Cart}
      className={classNames("cart_button", {
        "cart_button--current_state": pathname === ROUTES.Cart,
      })}
    >
      <span className="cart_button__counter">{count}</span>
      <span className="cart_button__icon font_icon">
        <ImCart />
      </span>
    </Link>
  );
};

export default CartButton;
