import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveToken, setPasswordPopupOpen } from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import { LOCAL_STORAGE } from "../../../../constants";
import i18n from "../../../../locales";

interface IConfirmWorker {
  type: string;
  payload: {
    payload: string;
  };
}

function* confirmWorker({ payload }: IConfirmWorker): SagaIterator {
  const confirmType = localStorage.getItem(LOCAL_STORAGE.confirmMod);

  try {
    yield put(setAppLoading(true));
    const params = {
      token: localStorage.getItem(LOCAL_STORAGE.token),
      confirmationCode: payload,
    };

    const { data, isError, message } = yield call(
      requester.post,
      confirmType === "email" ? links.confirmEmail : links.confirmPhone,
      params
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (data.message === "code_is_expite_or_wrong") {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t(data.message),
        type: "danger",
      });
    } else {
      yield put(saveToken(data.token));
      localStorage.setItem(LOCAL_STORAGE.token, data.token);
      yield put(setPasswordPopupOpen(true));

      createNotification({
        title: i18n.t("success"),
        message: "",
        type: "success",
      });
    }
  } catch (error) {
    console.error("confirmWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* confirmWatcher() {
  yield takeLatest("@saga/CONFIRM_CODE", confirmWorker);
}
