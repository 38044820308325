interface IInitialState {
  isLoading: boolean;
  prevPathname: string | null;
}

const initialState: IInitialState = {
  isLoading: false,
  prevPathname: null,
};

export function appReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SET_APP_LOADING":
      return { ...state, isLoading: action.payload };
    case "SAVE_PREV_PATHNAME":
      return { ...state, prevPathname: action.payload };

    default:
      return state;
  }
}
