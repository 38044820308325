import { useState, useCallback, FC } from "react";
import classNames from "classnames";
import { CLASSES } from "../../constants";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons/lib";
import Catalog from "../catalog/catalog";
import "./catalogTrigger.scss";

interface IProps {
  item: ICatalog;
  Icon: IconType;
}

const CatalogTrigger: FC<IProps> = ({ item, Icon }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    document.body.classList.add(CLASSES.wrapperBlurred);
    document.body.classList.add(CLASSES.bodyScrollHide);
    document.documentElement.classList.add(CLASSES.paddingLock);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    document.body.classList.remove(CLASSES.wrapperBlurred);
    document.body.classList.remove(CLASSES.bodyScrollHide);
    document.documentElement.classList.remove(CLASSES.paddingLock);
  }, []);

  const { name, childCategories } = item;

  return (
    <div
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      className={classNames("catalog_trigger", {
        "catalog_trigger--open_state": isOpen,
      })}
    >
      <div className="catalog_trigger__wrap">
        <span className="catalog_trigger__icon font_icon">
          <Icon />
        </span>
        <span className="catalog_trigger__text">{name}</span>
        <span className="catalog_trigger__arrow font_icon">
          <FiChevronDown />
        </span>
      </div>
      {childCategories?.length && isOpen && (
        <div className="catalog_trigger__container">
          <div className="catalog_trigger__body">
            <Catalog
              onClickLink={handleClose}
              depthLevel={0}
              items={childCategories}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CatalogTrigger;
