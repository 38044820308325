interface MinimalPriceData {
  minimalPrice: string | number;
  currency: string | undefined;
  previousPrice: number | undefined;
};

interface IProps {
  price: string | number;
  largeWholesalePrice?: string | number;
  wholesalePrice?: string | number;
  oldPrice: number | undefined; 
  oldWholesalePrice: number | undefined;
  oldLargeWholesalePrice: number | undefined;
};

const useMinimalPrice = ({ largeWholesalePrice, wholesalePrice, price, oldPrice, oldWholesalePrice, oldLargeWholesalePrice }: IProps): MinimalPriceData => {
  if (largeWholesalePrice) {
    return { minimalPrice: largeWholesalePrice, currency: "ye", previousPrice: oldLargeWholesalePrice };
  } else if (wholesalePrice) {
    return { minimalPrice: wholesalePrice, currency: "ye", previousPrice: oldWholesalePrice };
  }

  return { minimalPrice: price, currency: undefined, previousPrice: oldPrice };
};

export default useMinimalPrice;
