export type TPromo = "add" | "del";

export const findPercentFromPrice = (
  price: number,
  percent: number,
  type: TPromo
) => {
  const discount = (price * percent) / 100;
  const newPrice = price - discount;
  return type === "add" ? Number(newPrice.toFixed(2)) : price;
};
