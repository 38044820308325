import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveCart, setCartLoading } from "../../repository/actions";

interface IDeleteCartProductWorker {
  type: string;
  payload: {
    payload: number;
  };
}

function* deleteCartProductWorker({
  payload,
}: IDeleteCartProductWorker): SagaIterator {
  try {
    const { data, isError, message } = yield call(
      requester.delete,
      `${links.deleteFromOrder}/${payload}`,
      undefined,
      undefined,
      true
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      if (!data) return;

      yield put(saveCart(data));
    }
  } catch (error) {
    console.error("deleteCartProductWorker: ", error);
  } finally {
  }
}

export function* deleteCartProductWatcher() {
  yield takeLatest("@saga/DELETE_CART_PRODUCT", deleteCartProductWorker);
}
