import { FC, ReactNode, ChangeEventHandler } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import Textarea from "../textarea/textarea";
import Label from "../label/label";
import "./textareaField.scss";

interface IProps {
  id: string;
  label: ReactNode;
  register: UseFormRegisterReturn;
  autoComplete?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const TextareaField: FC<IProps> = ({ id, label, required, ...props }) => {
  return (
    <div className="textarea_field">
      <Label id={id} required={required}>
        {label}
      </Label>
      <Textarea id={id} required={required} {...props} />
    </div>
  );
};

export default TextareaField;
