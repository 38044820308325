import { FC } from "react";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import classNames from "classnames";
import CatalogItem from "../catalogItem/catalogItem";

import "./catalog.scss";

interface IProps {
  items: ICatalog[];
  depthLevel: number;
  isOpen?: boolean;
  onClickLink: () => void;
}

const Catalog: FC<IProps> = ({ items, depthLevel, isOpen, onClickLink }) => {
  depthLevel = depthLevel + 1;

  return (
    <div
      className={classNames("catalog", {
        "catalog--submenu_mod": depthLevel > 1,
        "catalog--open_state": isOpen,
      })}
    >
      <ul className="catalog__list">
        {items.map((item) => {
          return (
            <li key={item.id} className="catalog__item">
              <CatalogItem
                depthLevel={depthLevel}
                item={item}
                onClickLink={onClickLink}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Catalog;
