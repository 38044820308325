import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import i18n from "../../locales";
import { IRestorePassData } from "../../store/user/repository/IRestorePassData";
import { restorePass } from "../../store/user/useCases/restorePass/action";
import { createNotification } from "../../utils/createNotification";
import Input from "../input/input";
import { FormLayout, FormLayoutItem } from "../formLayout";

const ForgotPasswordForm = () => {
  const { register, handleSubmit } = useForm<IRestorePassData>();
  const dispatch = useDispatch();

  const isPhoneNumber = (input: string) => {
    const regex = /^\+380\d{9}$/;
    return regex.test(input);
  };
  const isEmail = (input: string) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(input);
  };

  const onSubmit = (data: IRestorePassData) => {
    const { input } = data;

    if (isEmail(input)) {
      dispatch(restorePass({ input: "email", email: input }));
      return;
    }
    if (isPhoneNumber(input)) {
      dispatch(restorePass({ input: "phone", phone: input }));
      return;
    }

    createNotification({
      title: "",
      message: i18n.t("notEmailOrPhone"),
      type: "danger",
    });
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("continue")}>
      <FormLayoutItem>
        <Input
          register={register("input")}
          placeholder={`${i18n.t("emailOrPhone")}`}
          required
        />
      </FormLayoutItem>
    </FormLayout>
  );
};

export default ForgotPasswordForm;
