import { FC } from "react";
import { ICharacteristic } from "../../store/products/repository/ICharacteristic";
import "./characteristics.scss";

interface IProps {
  promo?: any;
  data: ICharacteristic[];
}; 

const Characteristics: FC<IProps> = ({ data, promo }) => {
  return (
    <table className="characteristics">
      <tbody className="characteristics__body">
        {data.map(({ key, value, oldValue }) => (
          <tr className="characteristics__item" key={key}>
            <td>{key}</td>
            <td>
              {value !== oldValue &&
                <div className="price__val price__val--old_mod">{oldValue}</div>}
              <div className={`price__val price__val--current_mod ${value === oldValue || !promo? "price__val--gray_mod" : ""
                }`}>{value}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Characteristics;
