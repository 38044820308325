import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  saveGroupProducts,
  setGroupProductsLoading,
} from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { SLIDES_PER_PAGE } from "../../../../constants";

interface IGetGroupProductsWorker {
  type: string;
  payload: number;
}

function* getGroupProductsWorker({
  payload,
}: IGetGroupProductsWorker): SagaIterator {
  const sortBy = [{ field: "isAvailable", order: "DESC" }];
  const params = {
    perPage: SLIDES_PER_PAGE,
    page: payload ?? 1,
    sortBy: JSON.stringify(sortBy),
  };

  try {
    yield put(setGroupProductsLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      links.groupList,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveGroupProducts(data));
    }
  } catch (error) {
    console.error("getGroupProductsWorker: ", error);
  } finally {
    yield put(setGroupProductsLoading(false));
  }
}

export function* getGroupProductsWatcher() {
  yield takeLatest("@saga/GET_GROUP_PRODUCTS", getGroupProductsWorker);
}
