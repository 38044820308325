import { ICartResponse } from "./ICartResponse";
import { IOrder } from "./IOrder";

export const saveOrders = (payload: IOrder[]) => ({
  type: "SAVE_ORDERS",
  payload,
});
export const saveOrdersPage = (payload: number) => ({
  type: "SAVE_ORDERS_PAGE",
  payload,
});
export const setOrdersLoading = (payload: boolean) => ({
  type: "SET_ORDERS_LOADING",
  payload,
});
export const setDislaimerPopupOpen = (payload: boolean) => ({
  type: "SET_DICLAIMER_POPUP_OPEN",
  payload,
});

export const saveCart = (payload: ICartResponse) => ({
  type: "SAVE_CART",
  payload,
});
export const setCartChange = (payload: number | string) => ({
  type: "SET_CART_CHANGE",
  payload,
});
export const saveCartPage = (payload: number) => ({
  type: "SAVE_CART_PAGE",
  payload,
});
export const setCartLoading = (payload: boolean) => ({
  type: "SET_CART_LOADING",
  payload,
});

export const setAddToOrderLoading = (payload: boolean) => ({
  type: "SET_ADD_TO_ORDER_LOADING",
  payload,
});
export const saveAddToOrderLoadingId = (payload: number | null) => ({
  type: "SAVE_ADD_TO_ORDER_LOADING_ID",
  payload,
});

export const saveCurrentOrder = (payload: IOrder) => ({
  type: "SAVE_CURRENT_ORDER",
  payload,
});
export const setFactPaynamentMethod = (payload: boolean) => ({
  type: "SET_FACT_PAYNAMENT_METHOD",
  payload,
});
