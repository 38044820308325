import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { saveSingleNews, setSingleNewsLoading } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

interface ISingleNewsWorker {
  type: string;
  payload: number;
}

function* getSingleNewsWorker({ payload }: ISingleNewsWorker): SagaIterator {
  try {
    yield put(setSingleNewsLoading(true));

    const { data, isError, message } = yield call(
      requester.get,
      `${links.singleNews}/${payload}`
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveSingleNews(data));
    }
  } catch (error) {
    console.error("getSingleNewsWorker: ", error);
  } finally {
    yield put(setSingleNewsLoading(false));
  }
}

export function* getSingleNewsWatcher() {
  yield takeLatest("@saga/GET_SINGLE_NEWS", getSingleNewsWorker);
}
