import { useSelector } from "react-redux";
import { selectCurrentLang } from "../../store/language/repository/selectors";
import GooglePlay from "./icons/googlePlay";
import AppStore from "./icons/appStore";
import "./appButtons.scss";

const AppButtons = () => {
  const lang = useSelector(selectCurrentLang);

  return (
    <div className="app_buttons">
      <ul className="app_buttons__list">
        <li className="app_buttons__item">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.carshopams"
            className="app_buttons__link"
          >
            <span className="app_buttons__logo">
              <GooglePlay />
            </span>
          </a>
        </li>
        <li className="app_buttons__item">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://apps.apple.com/ua/app/apulse/id6445799660?l=${lang}`}
            className="app_buttons__link"
          >
            <span className="app_buttons__logo">
              <AppStore />
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AppButtons;
