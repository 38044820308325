import { useMemo } from "react";
import i18n from "../../locales";
import DropdownItem from "../dropdownItem/dropdownItem";
import { ROLES, ROUTES } from "../../constants";
import "./navBar.scss";
import { useSelector } from "react-redux";
import { selectisLoggedIn, selectUser } from "../../store/user/repository/selectors";

const NavBar = () => {
  const isLoggedIn = useSelector(selectisLoggedIn);
  const user = useSelector(selectUser);
  const links = useMemo(
    () => [
      {
        name: i18n.t("promotions"),
        url: ROUTES.PromotionList,
        id: 1,
        requiresAuth: true,
      },
      {
        name: i18n.t("news"),
        url: ROUTES.NewsPage,
        id: 2,
        requiresAuth: false,
      },
      {
        name: i18n.t("contacts"),
        url: ROUTES.ContactsPage,
        id: 3,
        requiresAuth: false,
      },
    ],
    []
  );

  const hasOnlyUserRole = user?.roles.length === 1 && user.roles[0].name === ROLES.user;

  const filteredLinks = useMemo(() => {
    return links.filter(link => !link.requiresAuth || 
      (isLoggedIn && !hasOnlyUserRole));
  }, [links, isLoggedIn]);

  return (
    <nav className="nav_bar">
      <ul className="nav_bar__list">
        {filteredLinks.map((item) => (
          <li key={item.id} className="nav_bar__item">
            <DropdownItem item={item} depthLevel={0} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
