import i18n from "../../locales";
import { FC, useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSettings } from "../../store/products/repository/selectors";
import { saveCurrentSettings } from "../../store/products/repository/actions";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import ProductCard from "../productCard/productCard";
import { IProduct } from "../../store/products/repository/IProduct";
import Pagination from "../pagination/pagination";
import { PRODUCTS_PER_PAGE } from "../../constants";
import "./productList.scss";

interface IProps {
  products: IProduct[];
  categoryProducts?: boolean;
  description?: string;
  searchMod?: boolean;
  count?: number;
  onDelete?: (id: number) => void;
};

const ProductList: FC<IProps> = ({ products, description, searchMod = false, categoryProducts = false, count, onDelete, }) => {
  const currentSettings = useSelector(selectCurrentSettings);
  const [activeItem, setActiveItem] = useState(currentSettings?.page ?? 1);
  const dispatch = useDispatch();

  const paginationClick = useCallback(
    (page: number) => {
      dispatch(getCategoryProducts({ ...currentSettings, page }));
      dispatch(saveCurrentSettings({ ...currentSettings, page }));
      setActiveItem(page);
    },
    [currentSettings]
  );

  useEffect(() => {
    if (!currentSettings?.page) setActiveItem(1);
  }, [currentSettings?.page]);

  if (products.length <= 0) {
    if (categoryProducts) {
      return (
        <div className="product_list__error">{i18n.t("noResultByFilter")}</div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classNames("product_list", { "product_list--search_mod": searchMod })}>
      <div className="product_list__container">
        <ul className="product_list__body">
          {products.map((product) => {
            return (
              <li key={product.id} className="product_list__item">
                <ProductCard onDelete={onDelete} product={product} />
              </li>
            );
          })}
        </ul>
      </div>
      {count && count > PRODUCTS_PER_PAGE && (
        <Pagination
          pagesCount={count}
          onClick={paginationClick}
          activeItem={activeItem}
        />
      )}
      {description && (
        <div className="product_list__description">{description}</div>
      )}
    </div>
  );
};

export default ProductList;
