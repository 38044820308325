import { FC } from "react";
import i18n from "../../locales";
import "./confirmPopup.scss";

interface IProps {
  title: string;
  onConfirm: () => void;
  onDecline: () => void;
}

const ConfirmPopup: FC<IProps> = ({ title, onConfirm, onDecline }) => {
  return (
    <div className="confirm_popup">
      <h4 className="confirm_popup__title">{title}</h4>
      <div className="confirm_popup__wrapper">
        <button
          onClick={onConfirm}
          className="confirm_popup__button confirm_popup__button--confirm_mod"
        >
          {i18n.t("yes")}
        </button>
        <button
          onClick={onDecline}
          className="confirm_popup__button confirm_popup__button--decline_mod"
        >
          <span>{i18n.t("no")}</span>
        </button>
      </div>
    </div>
  );
};

export default ConfirmPopup;
