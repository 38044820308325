import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

interface IDeleteFromFavoriteWorker {
  type: string;
  payload: {
    payload: number;
  };
}

function* deleteFromFavoriteWorker({
  payload,
}: IDeleteFromFavoriteWorker): SagaIterator {
  try {
    const { isError, message } = yield call(
      requester.post,
      links.deleteFromFavorite,
      { productId: payload }
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      createNotification({
        title: i18n.t("successDeleteFavorite"),
        message,
        type: "success",
      });
    }
  } catch (error) {
    console.error("deleteFromFavoriteWorker: ", error);
  }
}

export function* deleteFromFavoriteWatcher() {
  yield takeLatest("@saga/DELETE_FROM_FAVORITE", deleteFromFavoriteWorker);
}
