import classNames from "classnames";
import { UseFormSetValue } from "react-hook-form";
import { FC, useState } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useMedia } from "../../customHooks";
import "./markRating.scss";

interface IProps {
  setValue?: UseFormSetValue<any>;
  required?: boolean;
  name?: string;
}

const MarkRating: FC<IProps> = ({ required, setValue, name = "rating" }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const isMobile = useMedia();

  const handleChange = (number: number) => {
    setRating(number);
    setValue && setValue(name, number);
  };

  return (
    <div className="mark_rating">
      {[1, 2, 3, 4, 5].map((item) => (
        <label key={item} className="mark_rating__star font_icon">
          <div className="mark_rating__wrap">
            <input
              required={required}
              type="radio"
              name="rating"
              value={item}
              onChange={() => handleChange(item)}
            />
          </div>
          <div className="mark_rating__outline">
            <AiOutlineStar />
          </div>
          <div
            onMouseEnter={isMobile ? undefined : () => setHover(item)}
            onMouseLeave={isMobile ? undefined : () => setHover(rating)}
            className={classNames("mark_rating__fill", {
              "mark_rating__fill--visible_mod": item <= (hover || rating),
            })}
          >
            <AiFillStar />
          </div>
        </label>
      ))}
    </div>
  );
};

export default MarkRating;
