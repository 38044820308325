import { IRegisterData } from "./IRegisterData";
import { IUser } from "./IUser";
import { TAuthType } from "../../../types/authType";
import { IRestorePassData } from "./IRestorePassData";
import { LOCAL_STORAGE } from "../../../constants";

interface IInitialState {
  user: IUser | null;
  token: string | null;
  registerData: IRegisterData | null;
  restoreData: IRestorePassData | null;
  isPopupOpen: boolean;
  isPasswordPopupOpen: boolean;
  isConfirmation: boolean;
  isLoggedIn: string | null;
  authType: TAuthType | null;
  isInfoPopupOpen: boolean;
  updateUserLoading: boolean;
  confirmEmailPopupOpen: boolean;
}

const initialState: IInitialState = {
  user: null,
  token: localStorage.getItem(LOCAL_STORAGE.token),
  registerData: null,
  restoreData: null,
  isPopupOpen: false,
  isPasswordPopupOpen: false,
  isConfirmation: false,
  isLoggedIn: localStorage.getItem(LOCAL_STORAGE.loggedIn),
  authType: null,
  isInfoPopupOpen: false,
  updateUserLoading: false,
  confirmEmailPopupOpen: false,
};

export function userReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_USER":
      return { ...state, user: action.payload };
    case "SAVE_TOKEN":
      return { ...state, token: action.payload.token };
    case "SAVE_REGISTER_DATA":
      return { ...state, registerData: action.payload };
    case "SAVE_RESTORE_DATA":
      return { ...state, restoreData: action.payload };
    case "SET_AUTH_POPUP_OPEN":
      return { ...state, isPopupOpen: action.payload };
    case "SET_PASSWORD_POPUP_OPEN":
      return { ...state, isPasswordPopupOpen: action.payload };
    case "SET_CONFIRMATION":
      return { ...state, isConfirmation: action.payload };
    case "SET_LOGGED_IN":
      return { ...state, isLoggedIn: action.payload };
    case "SET_AUTH_TYPE":
      return { ...state, authType: action.payload };
    case "SET_INFO_POPUP_OPEN":
      return { ...state, isInfoPopupOpen: action.payload };
    case "SET_UPDATE_USER_LOADING":
      return { ...state, updateUserLoading: action.payload };
    case "SET_CONFIRM_EMAIL_POPUP_OPEN":
      return { ...state, confirmEmailPopupOpen: action.payload };

    default:
      return state;
  }
}
