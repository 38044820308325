import { IWaitingResponse } from "./IWaitingResponse";

interface IInitialState {
  waitingList: IWaitingResponse;
}

const initialState: IInitialState = {
  waitingList: { data: [], count: 0 },
};

export function waitingReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_WAITING_LIST":
      return { ...state, waitingList: action.payload };

    default:
      return state;
  }
}
