import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

interface IAddToFavoriteWorker {
  type: string;
  payload: {
    payload: number;
  };
}

function* addToFavoriteWorker({ payload }: IAddToFavoriteWorker): SagaIterator {
  try {
    const { isError, message } = yield call(
      requester.post,
      links.addToFavorite,
      { productId: payload }
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      createNotification({
        title: i18n.t("successAddedFavorite"),
        message,
        type: "success",
      });
    }
  } catch (error) {
    console.error("addToFavoriteWorker: ", error);
  }
}

export function* addToFavoriteWatcher() {
  yield takeLatest("@saga/ADD_TO_FAVORITE", addToFavoriteWorker);
}
