import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, put, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import i18n from "../../../../locales";
import { getNotifications } from "../getNotifications/action";

function* setNotificationsReadAllWorker({ payload }: any): SagaIterator {
  try {
    const { isError } = yield call(requester.put, links.notificationReadAll);
    if (!isError) {
      createNotification({
        title: i18n.t("success"),
        message: i18n.t("messagesIsRead"),
        type: "success",
      });
    yield put(getNotifications(payload.activeItem)); 
    }
  } catch (error) {
    console.error("setNotificationsReadAllWorker: ", error);
  }
};

export function* setNotificationsReadAllWatcher() {
  yield takeLatest("@saga/SET_NOTIFICATIONS_READ_ALL", setNotificationsReadAllWorker);
};
