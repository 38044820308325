import i18n from "../../locales";
import { FC, useMemo, useCallback, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../constants";
import { selectCurrentSettings } from "../../store/products/repository/selectors";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { saveCurrentSettings } from "../../store/products/repository/actions";
import { selectUser, selectisLoggedIn } from "../../store/user/repository/selectors";
import CustomSelect from "../customSelect/customSelect";
import "./productsSort.scss";

interface IOption {
  label: string;
  value: string;
};

interface IProps {
  searchMod?: boolean;
};

const ProductsSort: FC<IProps> = ({ searchMod }) => {
  const { id, name } = useParams();
  const dispatch = useDispatch();

  const location = useLocation();
  const isPromo = location.pathname.includes('promotions');
  
  const currentSettings = useSelector(selectCurrentSettings);
  const user = useSelector(selectUser);

  const [optionsChange, setOptionChange] = useState(false);
  const [options, setOptions] = useState([
    { label: i18n.t("ASC"), value: "ASC" },
    { label: i18n.t("DESC"), value: "DESC" },
  ]);

  const findInOption = useCallback(
    (value: string): IOption | undefined => {
      return options.find((option) => option.value === value);
    },
    [options]
  );

  const setInitialValue = useMemo(() => {
    if (currentSettings && currentSettings?.sortBy) {
      const order = currentSettings.sortBy[0].order;
      const field = currentSettings.sortBy[0].field;

      if (field === "promo") return findInOption(field + order);
      return findInOption(order);
    }

    return undefined;
  }, [currentSettings, findInOption]);

  const handleChange = useCallback(
    (selectedOption: IOption | null) => {
      let sortBy;

      if (selectedOption === null) {
        sortBy = undefined;
      } else if (selectedOption.value.includes("promo")) {
        const promoOption = selectedOption.value.replace("promo", "");
        sortBy = [{ field: "promo", order: promoOption }];
      } else {
        sortBy = [{ field: "price", order: selectedOption.value }];
      }

      if (searchMod) {
        dispatch(getCategoryProducts({ sortBy, isPromo }, name)); 
        dispatch(saveCurrentSettings({ sortBy, name, isPromo }));
      } else {
        dispatch(getCategoryProducts({ ...currentSettings, sortBy, isPromo }));
        dispatch(saveCurrentSettings({ ...currentSettings, sortBy, isPromo }));
      }
    },
    [currentSettings, name, searchMod]
  );

  useEffect(() => {
    if (!user || optionsChange) return;

    if (user.roles.length === 1 && user.roles[0].name === ROLES.user) return;

    setOptions((prev) => [
      ...prev,
      { label: i18n.t("promoASC"), value: "promoASC" },
      { label: i18n.t("promoDESC"), value: "promoDESC" },
    ]);
    setOptionChange(true);
  }, [user?.roles]);

  return (
    <div
      key={JSON.stringify(currentSettings?.sortBy) + id}
      className="products_sort"
    >
      <div className="products_sort__label">{i18n.t("sortBy")}:</div>
      <div
        key={JSON.stringify(currentSettings?.sortBy)}
        className="products_sort__select"
      >
        <CustomSelect
          options={options}
          onValueChange={handleChange}
          isSearchable={false}
          defaultValue={setInitialValue}
          isClearable
          name="sort"
        />
      </div>
    </div>
  );
};

export default ProductsSort;
