import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteFromFavorite } from "../../store/favorites/useCases/deleteFromFavorite/action";
import { addToFavorite } from "../../store/favorites/useCases/addFavorite/action";
import {
  saveLikedProducts,
  saveUnlikedProducts,
} from "../../store/favorites/repository/actions";
import {
  selectLikedProducts,
  selectUnlikedProducts,
} from "../../store/favorites/repository/selectors";
import { setAuthPopupOpen } from "../../store/user/repository/actions";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { ROUTES } from "../../constants";
import classNames from "classnames";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import "./likeButton.scss";

interface IProps {
  productId: number;
  isActive?: boolean;
}

const LikeButton: FC<IProps> = ({ isActive = false, productId }) => {
  const { pathname } = useLocation();
  const isFavoritesPage = pathname === ROUTES.Favorites;
  const [isButtonActive, setIsButtonActive] = useState(
    isFavoritesPage ? true : isActive
  );
  const unlikedProducts = useSelector(selectUnlikedProducts);
  const likedProducts = useSelector(selectLikedProducts);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (likedProducts.includes(productId)) setIsButtonActive(true);
    if (unlikedProducts.includes(productId)) setIsButtonActive(false);
  }, []);

  const handleClick = () => {
    if (isLoggedIn !== "true") {
      dispatch(setAuthPopupOpen(true));
      return;
    }

    if (isButtonActive) {
      dispatch(deleteFromFavorite(productId));

      dispatch(saveUnlikedProducts([...unlikedProducts, productId]));
      dispatch(
        saveLikedProducts(likedProducts.filter((id) => id !== productId))
      );
    } else {
      dispatch(addToFavorite(productId));

      dispatch(saveLikedProducts([...likedProducts, productId]));
      dispatch(
        saveUnlikedProducts(unlikedProducts.filter((id) => id !== productId))
      );
    }

    setIsButtonActive((prev) => !prev);
  };

  return (
    <button
      onClick={handleClick}
      className={classNames("like_button", {
        "like_button--active_state": isButtonActive,
      })}
      type="button"
      aria-label="Add to favorite"
    >
      <span className="like_button__item font_icon">
        <AiOutlineHeart />
      </span>
      <span className="like_button__item like_button__item--filled_mod font_icon">
        <AiFillHeart />
      </span>
    </button>
  );
};

export default LikeButton;
