import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import i18n from "../../locales";
import { IProduct } from "../../store/products/repository/IProduct";
import Image from "../image/image";
import "./orderProduct.scss";
import Price from "../price/price";

interface IProps extends IProduct {
  count?: number;
};

const OrderProduct = ({ id, images, name, price, count, currency, oldPrice, code1c, promo }: IProps) => {
  if (!id) return null;

  return (
    <Link to={`${ROUTES.SingleProduct}/${id}`} className="order_product">
      <div className="order_product__image">
        {images && images[0] && (
          <Image
            imgClass="order_product__picture"
            src={images[0].fileUrl}
            alt={name}
          />
        )}
      </div>

      <div className="order_product__name">
        <div className="order_product__name__title">{name}</div>
        {code1c && (
          <div className="cart_product__code">
            {i18n.t("productCode")}: <strong>{code1c}</strong>
          </div>
        )}
      </div>

      <div className="order_product__amount">
        {count ?? 1} {i18n.t("piece")}
      </div>
      <div className="order_product__price">
        <Price
          price={price}
          oldPrice={oldPrice}
          currency={currency}
          size="xs"
          promo={promo}
        />
      </div>
    </Link>
  );
};

export default OrderProduct;
