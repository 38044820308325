import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getPromoList } from "../../store/promo/useCases/getPromoList/action";
import PromotionSection from "../../ui-kit/promotionSection/promotionSection";

const PromotionPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPromoList({ page: 1, perPage: 20 }));
    }, [dispatch]);

    return (
        <PromotionSection />
    );
};

export default PromotionPage;
