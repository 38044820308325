export const PaymentPrivat = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 0 512 512"
    >
      <path
        fill="#5fba46"
        d="M0 429.54h31.84c6.87 0 12.060 1.68 15.57 4.91 3.51 3.37 5.19 8 5.19 14.030 0 6.31-1.82 11.22-5.61 14.73s-9.54 5.33-17.25 5.33h-10.52v23h-19.22v-62zM19.22 456.050h4.63c3.65 0 6.31-0.7 7.72-1.96 1.54-1.26 2.24-2.95 2.24-4.91s-0.7-3.65-1.96-4.91c-1.26-1.4-3.79-1.96-7.29-1.96h-5.47v13.75h0.13z"
      />
      <path
        fill="#5fba46"
        d="M61.72 446.65h16.13v7.29c1.54-3.23 3.090-5.33 4.77-6.59s3.65-1.82 6.17-1.82c2.52 0 5.33 0.84 8.28 2.38l-5.33 12.2c-1.96-0.84-3.65-1.26-4.77-1.26-2.24 0-4.070 0.98-5.19 2.81-1.82 2.67-2.67 7.57-2.67 14.73v15.010h-17.25v-44.75h-0.14z"
      />
      <path
        fill="#5fba46"
        d="M100.58 429.54h17.25v11.78h-17.25v-11.78zM100.58 446.65h17.25v44.89h-17.25v-44.89z"
      />
      <path
        fill="#5fba46"
        d="M123.72 446.65h17.96l8.69 28.48 8.98-28.48h17.4l-19.080 44.89h-15.29z"
      />
      <path
        fill="#5fba46"
        d="M197.37 461.24l-16.41-1.68c0.56-2.95 1.54-5.19 2.67-6.73 1.12-1.68 2.81-3.090 5.050-4.35 1.54-0.84 3.79-1.54 6.45-1.96 2.81-0.42 5.75-0.7 8.98-0.7 5.19 0 9.26 0.28 12.48 0.84 3.090 0.56 5.75 1.82 7.86 3.65 1.4 1.26 2.67 3.090 3.51 5.33 0.84 2.38 1.26 4.49 1.26 6.59v19.77c0 2.1 0.14 3.79 0.42 4.91 0.28 1.26 0.84 2.67 1.82 4.63h-16.13c-0.7-1.12-1.12-2.1-1.26-2.67-0.14-0.56-0.42-1.54-0.56-2.81-2.24 2.1-4.49 3.79-6.73 4.63-3.090 1.26-6.59 1.82-10.66 1.82-5.33 0-9.4-1.26-12.2-3.79-2.81-2.52-4.21-5.47-4.21-9.12 0-3.37 0.98-6.17 2.95-8.42 1.96-2.24 5.75-3.79 11.080-4.91 6.45-1.26 10.66-2.24 12.62-2.67 1.96-0.56 3.93-1.26 6.030-2.1 0-2.1-0.42-3.65-1.26-4.49s-2.38-1.26-4.63-1.26c-2.81 0-4.91 0.42-6.31 1.4-1.14 0.86-2.12 2.13-2.82 4.090zM212.37 470.22c-2.38 0.84-4.77 1.54-7.43 2.24-3.51 0.98-5.75 1.82-6.59 2.81-0.98 0.98-1.4 1.96-1.4 3.23 0 1.4 0.42 2.52 1.4 3.37 0.98 0.84 2.38 1.26 4.21 1.26 1.96 0 3.65-0.42 5.33-1.4s2.81-2.1 3.51-3.37c0.7-1.4 0.98-3.090 0.98-5.19v-2.95z"
      />
      <path
        fill="#5fba46"
        d="M258.81 429.54v17.11h9.54v12.62h-9.54v15.85c0 1.96 0.14 3.23 0.56 3.79 0.56 0.98 1.54 1.4 2.95 1.4 1.26 0 3.090-0.42 5.33-1.12l1.26 11.92c-4.21 0.98-8.28 1.4-11.92 1.4-4.35 0-7.43-0.56-9.54-1.68-1.96-1.12-3.51-2.81-4.49-5.050s-1.4-5.89-1.4-10.94v-15.84h-6.31v-12.62h6.31v-8l17.25-8.84z"
      />
      <path
        fill="#5fba46"
        d="M277.6 429.54h35.91c6.030 0 10.52 1.54 13.75 4.49s4.77 6.59 4.77 10.94c0 3.65-1.12 6.87-3.37 9.4-1.54 1.68-3.79 3.090-6.73 4.21 4.49 1.12 7.72 2.95 9.82 5.47 2.1 2.67 3.090 5.89 3.090 9.82 0 3.23-0.7 6.17-2.24 8.7-1.54 2.52-3.51 4.63-6.17 6.030-1.54 0.98-4.070 1.54-7.29 1.96-4.35 0.56-7.15 0.84-8.56 0.84h-33.1v-61.86h0.12zM296.96 453.95h8.28c2.95 0 5.050-0.56 6.17-1.54s1.82-2.52 1.82-4.49c0-1.82-0.56-3.23-1.82-4.21-1.12-0.98-3.23-1.54-6.17-1.54h-8.42v11.78h0.14zM296.96 478.21h9.82c3.37 0 5.61-0.56 7.010-1.82 1.4-1.12 1.96-2.81 1.96-4.77 0-1.82-0.7-3.37-1.96-4.35-1.4-1.12-3.65-1.68-7.010-1.68h-9.68v12.62h-0.14z"
      />
      <path
        fill="#5fba46"
        d="M359.52 461.24l-16.41-1.68c0.56-2.95 1.54-5.19 2.67-6.73 1.12-1.68 2.81-3.090 5.050-4.35 1.54-0.84 3.79-1.54 6.45-1.96 2.81-0.42 5.75-0.7 8.98-0.7 5.19 0 9.26 0.28 12.48 0.84 3.090 0.56 5.75 1.82 7.86 3.65 1.4 1.26 2.67 3.090 3.51 5.33 0.84 2.38 1.26 4.49 1.26 6.59v19.77c0 2.1 0.14 3.79 0.42 4.91 0.28 1.26 0.84 2.67 1.82 4.63h-16.13c-0.7-1.12-1.12-2.1-1.26-2.67-0.14-0.56-0.42-1.54-0.56-2.81-2.24 2.1-4.49 3.79-6.73 4.63-3.090 1.26-6.59 1.82-10.66 1.82-5.33 0-9.4-1.26-12.2-3.79-2.81-2.52-4.21-5.47-4.21-9.12 0-3.37 0.98-6.17 2.95-8.42s5.75-3.79 11.080-4.91c6.45-1.26 10.66-2.24 12.62-2.67 1.96-0.56 3.93-1.26 6.030-2.1 0-2.1-0.42-3.65-1.26-4.49s-2.38-1.26-4.63-1.26c-2.81 0-4.91 0.42-6.31 1.4-1.27 0.86-2.12 2.13-2.82 4.090zM374.53 470.22c-2.38 0.84-4.77 1.54-7.43 2.24-3.51 0.98-5.75 1.82-6.59 2.81-0.98 0.98-1.4 1.96-1.4 3.23 0 1.4 0.42 2.52 1.4 3.37 0.98 0.84 2.38 1.26 4.21 1.26 1.96 0 3.65-0.42 5.33-1.4s2.81-2.1 3.51-3.37c0.7-1.4 0.98-3.090 0.98-5.19v-2.95z"
      />
      <path
        fill="#5fba46"
        d="M401.6 446.65h15.99v7.29c2.38-2.95 4.77-5.19 7.29-6.45 2.38-1.26 5.47-1.96 8.98-1.96 4.77 0 8.56 1.4 11.22 4.21s4.070 7.15 4.070 13.19v28.62h-17.25v-24.69c0-2.81-0.56-4.77-1.54-6.030-0.98-1.12-2.52-1.82-4.35-1.82-2.1 0-3.79 0.84-5.050 2.38s-1.96 4.35-1.96 8.56v21.6h-17.25v-44.89h-0.15z"
      />
      <path
        fill="#5fba46"
        d="M459.4 429.54h17.67v32.12l12.77-15.010h21.18l-16.13 15.71 17.11 29.18h-19.36l-9.12-17.95-6.45 6.31v11.64h-17.67z"
      />
      <path fill="#231f20" d="M63.4 248.020h146.73v146.73h-146.73v-146.73z" />
      <path
        fill="#5fba46"
        d="M63.54 19.38v182.64h38.86c102.12 0 153.88 51.76 153.88 153.88v38.86h182.64v-375.38h-375.38zM361.35 97.090v0c0 49.8 0 170.29 0 220.090-14.030 0-16.41 0-29.88 0-12.91-108.85-81.36-177.31-190.21-190.21 0-13.61 0-15.99 0-30.020 49.79 0.14 170.29 0.14 220.090 0.14z"
      />
    </svg>
  );
};
