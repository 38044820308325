import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCatalog } from "../../store/catalog/repository/selectors";
import CategoriesList from "../../ui-kit/categoriesList/categoriesList";
import { findCatalogById } from "../../utils/findCatalogById";

const CategoriesListPage = () => {
  const { id } = useParams();

  const catalog = useSelector(selectCatalog);
  const item = findCatalogById(parseInt(id || "0"), catalog);

  return (
    <CategoriesList
      id={item?.id ?? 0}
      childCategories={item?.childCategories}
      name={item?.name ?? ""}
      description={item?.description}
    />
  );
};

export default CategoriesListPage;
