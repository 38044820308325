import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { setAppLoading } from "../../../app/repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { IProductReview } from "../../repository/IProductReview";
import { setProductReviewsPopupOpen } from "../../repository/actions";

interface IAddProductReviewWorker {
  type: string;
  payload: {
    payload: IProductReview;
  };
}

function* addProductReviewWorker({
  payload,
}: IAddProductReviewWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const { data, isError, message } = yield call(
      requester.post,
      links.addReview,
      payload
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(setProductReviewsPopupOpen(true));
    }
  } catch (error) {
    console.error("getProductReviewWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* addProductReviewWatcher() {
  yield takeLatest("@saga/ADD_PRODUCT_REVIEW", addProductReviewWorker);
}
