import { IPriceListResponse } from "./IPriceListResponse";

interface IInitialState {
  priceList: IPriceListResponse | null;
  isPriceListLoading: boolean;
  shortPriceList: IPriceListResponse | null;
  isShortPriceListLoading: boolean;
}

const initialState: IInitialState = {
  priceList: null,
  isPriceListLoading: false,
  shortPriceList: null,
  isShortPriceListLoading: false,
};

export function priceListReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PRICE_LIST":
      return { ...state, priceList: action.payload };

    case "SET_PRICE_LIST_LOADING":
      return { ...state, isPriceListLoading: action.payload };

    case "SAVE_SHORT_PRICE_LIST":
      return { ...state, shortPriceList: action.payload };

    case "SET_SHORT_PRICE_LIST_LOADING":
      return { ...state, isShortPriceListLoading: action.payload };

    default:
      return state;
  }
}
