import { useEffect } from "react";
import NotFoundSection from "../../ui-kit/notFoundSection/notFoundSection";

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return <NotFoundSection />;
};

export default NotFoundPage;
