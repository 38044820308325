import { FC } from "react";
import { INewsColumn } from "../../../store/news/repository/INews";
import SanitaizedText from "../../sanitaizedText/sanitaizedText";
import Image from "../../image/image";

interface IProps {
  main?: INewsColumn;
  secondary?: INewsColumn;
}

const DoubleColumn: FC<IProps> = ({ main, secondary }) => {
  return (
    <>
      <div className="single_news_content__column single_news_content__column--half_mod">
        {main?.type === "TEXT" && (
          <div className="single_news_content__info">
            <SanitaizedText
              className="single_news_content__text"
              text={main?.value}
            />
          </div>
        )}
        {main?.type === "IMAGE" && (
          <div className="single_news_content__image">
            <Image src={main?.value} />
          </div>
        )}
      </div>
      <div className="single_news_content__column single_news_content__column--half_mod">
        {secondary?.type === "TEXT" && (
          <div className="single_news_content__info">
            <SanitaizedText
              className="single_news_content__text"
              text={secondary?.value}
            />
          </div>
        )}
        {secondary?.type === "IMAGE" && (
          <div className="single_news_content__image">
            <Image src={secondary?.value} />
          </div>
        )}
      </div>
    </>
  );
};

export default DoubleColumn;
