import { IRootState } from "../../store";

export const selectGroupProducts = (state: IRootState) =>
  state.groupProducts.groupList;

export const selectGroupProductsLoading = (state: IRootState) =>
  state.groupProducts.isGroupProductsLoading;

export const selectPaginationGroupId = (state: IRootState) =>
  state.groupProducts.paginationGroupId;
