import { FC } from "react";
import { ROUTES } from "../../constants";
import { INews } from "../../store/news/repository/INews";
import { Link } from "react-router-dom";
import { transformDate } from "../../utils/transformDate";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Image from "../image/image";
import "./newsCard.scss";

interface IProps {
  item: INews;
}

const NewsCard: FC<IProps> = ({ item }) => {
  const { id, title, image, created_at } = item;
  const date = transformDate(created_at);

  return (
    <Link to={`${ROUTES.NewsPage}/${id}`} className="news_card">
      <div className="news_card__wrap">
        <Image imgClass="news_card__image" src={image} />
      </div>
      <div className="news_card__content">
        <h2 title={title} className="news_card__title">
          {title}
        </h2>
        <div className="news_card__row">
          <div className="news_card__date">{date}</div>
          <div className="news_card__arrow font_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
