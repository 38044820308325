import { ReactNode } from "react";
import classNames from "classnames";
import { PaymentMastercard } from "./icons/paymentMastercard";
import { PaymentVisa } from "./icons/paymentVisa";
import { PaymentPrivat } from "./icons/paymentPrivat";
import "./payments.scss";

const Payments = () => {
  const logos = [<PaymentMastercard />, <PaymentVisa />, <PaymentPrivat />];

  return (
    <div className="payments">
      <ul className="payments__list">
        {logos.map((Logo: ReactNode, index: number) => {
          const itemClasses = classNames(
            "payments__logo",
            `payments__logo--mod_${index + 1}`
          );

          return (
            <li key={index} className="payments__item">
              <div className={itemClasses}>{Logo}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Payments;
