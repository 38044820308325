import { IPriceListResponse } from "../../excel/repository/IPriceListResponse";

interface IInitialState {
  productsFile: IPriceListResponse | null;
  isProductsFileLoading: boolean;
}

const initialState: IInitialState = {
  productsFile: null,
  isProductsFileLoading: false,
};

export function xmlReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PRODUCTS_FILE":
      return { ...state, productsFile: action.payload };

    case "SET_PRODUCTS_FILE_LOADING":
      return { ...state, isProductsFileLoading: action.payload };

    default:
      return state;
  }
}
