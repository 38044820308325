import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { savePriceList, setPriceListLoading } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

function* getPriceListWorker(): SagaIterator {
  try {
    yield put(setPriceListLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      links.getPriceList
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(savePriceList(data));
    }
  } catch (error) {
    console.error("getPriceListWorker: ", error);
  } finally {
    yield put(setPriceListLoading(false));
  }
}

export function* getPriceListWatcher() {
  yield takeLatest("@saga/GET_PRICE_LIST", getPriceListWorker);
}
