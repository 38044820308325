import i18n from "../../locales";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductReviews } from "../../store/reviews/useCases/getProductReviews/action";
import { saveProductReviewsPage } from "../../store/reviews/repository/actions";
import {
  selectProductReviewsLoading,
  selectProductReviewsPage,
} from "../../store/reviews/repository/selectors";
import { transformDate } from "../../utils/transformDate";
import { FC } from "react";
import { IReviewsResponse } from "../../store/reviews/repository/IReviewsResponse";
import { REVIEWS_PER_PAGE } from "../../constants";
import Rating from "../rating/rating";
import ReviewItem from "../reviewItem/reviewItem";
import AnchorScroll from "../anchorScroll/anchorScroll";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import "./reviews.scss";

interface IProps {
  productId: number;
  reviews: IReviewsResponse;
  title: string;
  average?: number;
  scrollY?: number;
}

const Reviews: FC<IProps> = ({
  productId,
  reviews,
  title,
  average,
  scrollY = 0,
}) => {
  const dispatch = useDispatch();
  const isReviewsLoading = useSelector(selectProductReviewsLoading);
  const currentPage = useSelector(selectProductReviewsPage);
  const { data, count } = reviews;
  const [activeItem, setActiveItem] = useState(currentPage ?? 1);

  const paginationClick = (page: number) => {
    dispatch(getProductReviews(productId, page));
    dispatch(saveProductReviewsPage(page));
    setActiveItem(page);
  };

  return (
    <div className="reviews">
      {isReviewsLoading && (
        <div className="reviews__loader">
          <Loader isLocal />
        </div>
      )}
      {!data.length ? (
        <div className="reviews__head reviews__head--no_reviews_mod">
          <div className="reviews__block">
            <h3 className="reviews__title">{title}</h3>
            <div className="reviews__text">
              <p>
                <span>{i18n.t("noReviews")}</span>
              </p>
            </div>
            <AnchorScroll targetId="write_review_form">
              <div className="reviews__button">
                <span>{i18n.t("writeReview")}</span>
              </div>
            </AnchorScroll>
          </div>
        </div>
      ) : (
        <div className="reviews__block">
          <div className="reviews__head">
            <div className="reviews__wrap">
              <h3 className="reviews__title">
                {title} ({count})
              </h3>
              <div className="reviews__average_rating">
                <Rating rating={average} />
                {average && <span>{Math.round(average)}</span>}
              </div>
            </div>
            <AnchorScroll targetId="write_review_form">
              <div className="reviews__button">
                <span>{i18n.t("writeReview")}</span>
              </div>
            </AnchorScroll>
          </div>
          <ul className="reviews__list">
            {data.map(({ id, user, created_at, rating, comment }) => {
              const date = transformDate(created_at);

              return (
                <li key={id} className="reviews__item">
                  <ReviewItem
                    name={user.name}
                    date={date}
                    rating={rating}
                    comment={comment}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {count > REVIEWS_PER_PAGE && (
        <div>
          <Pagination
            scrollY={scrollY}
            pagesCount={count}
            perPage={REVIEWS_PER_PAGE}
            activeItem={activeItem}
            onClick={paginationClick}
          />
        </div>
      )}
    </div>
  );
};

export default Reviews;
