import { FC, useEffect, useState } from "react";
import { format, parseISO } from 'date-fns';
import './timer.scss';
import i18n from "../../locales";

interface IProps {
    startDate: string;
    endDate: string;
};

const Timer: FC<IProps> = ({ startDate, endDate }) => {
    const isValidDate = (date: string) => {
        return date && !isNaN(Date.parse(date));
    };

    const formattedStartDate = isValidDate(startDate) ? format(parseISO(startDate), 'dd.MM.yyyy') : '';
    const formattedEndDate = isValidDate(endDate) ? format(parseISO(endDate), 'dd.MM.yyyy') : '';

    const calculateTimeLeft = () => {
        const now = new Date();
        const end = isValidDate(endDate) ? new Date(endDate) : now;
        const difference = end.getTime() - now.getTime();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 100);

        return () => clearInterval(intervalId);
    }, [endDate]);

    return (
        <>
            {timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
                <div className="timer-container">
                    <div className="timer">
                        <div className="time-unit">
                            <span className="time-value">{timeLeft.days}</span>
                            <div className="time-label">{i18n.t('days')}</div>
                        </div>
                        <div className="separator"></div>
                        <div className="time-unit">
                            <span className="time-value">{timeLeft.hours}</span>
                            <div className="time-label">{i18n.t('hours')}</div>
                        </div>
                        <div className="separator"></div>
                        <div className="time-unit">
                            <span className="time-value">{timeLeft.minutes}</span>
                            <div className="time-label">{i18n.t('minutes')}</div>
                        </div>
                        <div className="separator"></div>
                        <div className="time-unit">
                            <span className="time-value red">{timeLeft.seconds}</span>
                            <div className="time-label red">{i18n.t('seconds')}</div>
                        </div>
                    </div>
                    <div className="promotion-period">
                        {formattedStartDate && formattedEndDate ? (
                            <span>{i18n.t('promoFrom')} {formattedStartDate} {i18n.t('promoTo')} {formattedEndDate}</span>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Timer;
