import i18n from "../../locales";
import { FC, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { ICatalogHistory } from "../../store/catalog/repository/ICatalogHistory";
import { selectCatalogHistory } from "../../store/catalog/repository/selectors";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import MegaItem from "../megaItem/megaItem";
import "./megaCatalog.scss";

interface IProps {
  childCategories: ICatalog[];
  name: string;
  currentId: number;
  open: boolean;
  id: number;
  onClose: () => void;
  onClickLink: () => void;
}

const MegaCatalog: FC<IProps> = ({
  childCategories,
  name,
  currentId,
  open,
  onClose,
  onClickLink,
  id,
}) => {
  const catalogRef = useRef<HTMLDivElement>(null);
  const catalogHistoryList: ICatalogHistory[] =
    useSelector(selectCatalogHistory);
  const isCurrent =
    catalogHistoryList.length &&
    currentId === catalogHistoryList[catalogHistoryList.length - 1].item.id;

  const catalogClasses = classNames("mega_catalog", {
    "mega_catalog--open_state": open,
    "mega_catalog--current_mod": isCurrent,
  });

  return (
    <div ref={catalogRef} className={catalogClasses}>
      <button onClick={onClose} type="button" className="menu_catalog__back">
        <span className="menu_catalog__arrow font_icon">
          <FiChevronDown />
        </span>
        {name}
      </button>
      <div className="menu_catalog__body">
        <ul className="mega_catalog__list">
          {id !== -1 && (
            <li className="mega_catalog__item">
              <Link
                to={`${ROUTES.Categories}/${id}`}
                className="mega_item__link"
                onClick={onClickLink}
              >
                <span className="mega_item__text">{i18n.t("allProducts")}</span>
              </Link>
            </li>
          )}
          {childCategories.map((item: ICatalog) => {
            return (
              <li key={item.id} className="mega_catalog__item">
                <MegaItem item={item} catalogRef={catalogRef} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MegaCatalog;
