import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import {
  setFavoritesProductsLoading,
  saveFavoritesProducts,
} from "../../repository/actions";
import { PRODUCTS_PER_PAGE } from "../../../../constants";

interface IGetFavoritesProductsWorker {
  type: string;
  payload: number;
}

function* getFavoritesProductsWorker({
  payload,
}: IGetFavoritesProductsWorker): SagaIterator {
  try {
    yield put(setFavoritesProductsLoading(true));
    const params = {
      page: payload ?? 1,
      perPage: PRODUCTS_PER_PAGE,
    };

    const { data, isError, message } = yield call(
      requester.get,
      links.favoritesList,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveFavoritesProducts(data));
    }
  } catch (error) {
    console.error("getFavoritesProductsWorker: ", error);
  } finally {
    yield put(setFavoritesProductsLoading(false));
  }
}

export function* getFavoritesProductsWatcher() {
  yield takeLatest("@saga/GET_FAVORITES_PRODUCTS", getFavoritesProductsWorker);
}
