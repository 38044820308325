import React from "react";

import "./logo.scss";

const Logo = () => {
  return (
    <div className="logo">
      <svg
        className="logo__in"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="3146"
        height="512"
        viewBox="0 0 3146 512"
      >
        <path
          fill="currentColor"
          d="M1758.039 160.246l-108.573 261.041h166.675l107.882-261.041h91.659l-142.937 345.865h-350.438l143.851-345.865z"
        ></path>
        <path
          fill="currentColor"
          d="M2168.358 160.246l-105.684 261.356 195.032 0.464-34.955 84.045-285.627-0.151 139.798-345.714z"
        ></path>
        <path
          fill="currentColor"
          d="M3111.858 245.236h-227.904l-19.993 50.103 227.943-0.102-33.791 84.671-227.941 0.102-16.798 42.091 228.081-0.443-33.705 84.454h-319.039l138.023-345.865h319.039z"
        ></path>
        <path
          fill="currentColor"
          d="M1190.42 176.579l-129.841 329.532h-91.627l36.038-91.264h-259.029l158.541-84.991h134.045l35.994-91.155z"
        ></path>
        <path
          fill="#F44036"
          d="M1454.743 0.001l-955.078 511.998h-499.664l1275.145-511.998z"
        ></path>
        <path
          fill="currentColor"
          d="M2735.184 245.232h-221.284l35.294-84.987h219.906l-33.916 84.987zM2458.356 291.026l258.401 0.378-85.68 214.708h-354.608l34.74-84.060h262.277l18.426-46.174-261.36-0.38 88.854-215.252h92.975c-18.214 43.86-36.489 86.832-54.027 130.781z"
        ></path>
        <path
          fill="currentColor"
          d="M1123.895 506.111l136.273-345.865h93.604l-35.294 84.987h-0.615l-11.386 28.9-96.34 231.979h-86.243zM1390.582 160.246h216.038l-99.112 238.294-215.946 0.143 35.165-84.68 135.219-0.092 26.576-68.679h-133.232l35.292-84.987z"
        ></path>
      </svg>
    </div>
  );
};

export default Logo;
