import i18n from "../../locales";
import Section from "../section/section";
import { BsTelephoneFill, BsFillClockFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import "./contactsSection.scss";

const ContactsSection = () => {
  return (
    <Section className="contacts_section">
      <h3>{i18n.t("contactUs")}</h3>
      <p>
        <strong>{i18n.t("saleDep")}:</strong>{" "}
        <a href="tel:0800335074">
          <span className="font_icon">
            <BsTelephoneFill />
          </span>
          0800335074
        </a>
      </p>
      <p>
        <strong>{i18n.t("techDep")}:</strong>{" "}
        <a href="tel:0800304744">
          <span className="font_icon">
            <BsTelephoneFill />
          </span>
          0800304744
        </a>
      </p>
      <p>
        <strong>E-mail {i18n.t("address").toLowerCase()}:</strong>{" "}
        <a href="mailto:info@apulse.com.ua">
          <span className="font_icon">
            <MdEmail />
          </span>
          info@apulse.com.ua
        </a>
      </p>
      <p>
        <strong>{i18n.t("address")}:</strong>{" "}
        <a
          target="_blank"
          href="https://maps.google.com/maps?q=49.981197%2C36.237327&amp;hl=ua-ua&amp;t=m&amp;z=15"
          rel="noreferrer"
        >
          <span className="font_icon">
            <IoLocation />
          </span>
          {i18n.t("address2")}
        </a>
      </p>
      <p>
        <strong>{i18n.t("workHour")}:</strong>{" "}
        <span className="font_icon">
          <BsFillClockFill />
        </span>
        {i18n.t("workHourVal")}
      </p>
    </Section>
  );
};

export default ContactsSection;
