import i18n from "../../locales";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FormLayout, FormLayoutItem } from "../formLayout";
import Input from "../input/input";
import { createNotification } from "../../utils/createNotification";
import { confirmCode } from "../../store/user/useCases/confirm/action";
import { LOCAL_STORAGE } from "../../constants";

interface IFormData {
  confirmationCode: string;
}

const ConfirmCodeForm = () => {
  const { register, handleSubmit } = useForm<IFormData>();
  const dispatch = useDispatch();
  const isConfirmTypeEmail =
    localStorage.getItem(LOCAL_STORAGE.confirmMod) === "email";

  const onSubmit = (data: IFormData) => {
    const { confirmationCode } = data;

    if (confirmationCode.length > 3 && confirmationCode.length < 6) {
      dispatch(confirmCode(confirmationCode));
    } else {
      createNotification({
        title: "",
        message: i18n.t("code_is_expite_or_wrong"),
        type: "danger",
      });
    }
  };

  return (
    <FormLayout
      onSubmit={handleSubmit(onSubmit)}
      resendButton
      button={i18n.t("confirm")}
    >
      <FormLayoutItem>
        <h3>
          {i18n.t(
            isConfirmTypeEmail ? "confirmCodeDescrEmail" : "confirmCodeDescr"
          )}
        </h3>
      </FormLayoutItem>
      <FormLayoutItem>
        <Input
          placeholder={`${i18n.t("enterCode")}`}
          register={register("confirmationCode")}
          required
        />
      </FormLayoutItem>
    </FormLayout>
  );
};

export default ConfirmCodeForm;
