import i18n from "../../locales";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IFeedbackData } from "../../store/feedback/repository/IFeedbackData";
import { createFeedback } from "../../store/feedback/useCases/createFeedback/action";
import Section from "../section/section";
import { FormLayout, FormLayoutItem } from "../formLayout";
import InputField from "../inputField/inputField";
import TextareaField from "../textareaField/textareaField";
import "./feedbackForm.scss";

const FeedbackForm = () => {
  const { register, handleSubmit, reset } = useForm<IFeedbackData>();
  const dispatch = useDispatch();

  const onSubmit = (data: IFeedbackData) => {
    dispatch(createFeedback(data));
    reset();
  };

  return (
    <Section className="feedback_form">
      <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("confirm")}>
        <FormLayoutItem>
          <h2>{i18n.t("writeUs")}</h2>
        </FormLayoutItem>
        <FormLayoutItem>
          <InputField
            label={`${i18n.t("name")}`}
            id="name"
            register={register("name")}
            placeholder={`${i18n.t("yourName")}`}
            required
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <InputField
            id="email"
            register={register("email")}
            type="email"
            label="Email"
            placeholder={`${i18n.t("enter")} Email`}
            required
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <TextareaField
            label={`${i18n.t("message")}`}
            id="comment"
            register={register("comment")}
            placeholder={`${i18n.t("yourMessage")}`}
            required
          />
        </FormLayoutItem>
      </FormLayout>
    </Section>
  );
};

export default FeedbackForm;
