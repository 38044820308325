import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { saveOrders, setOrdersLoading } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { PRODUCTS_PER_PAGE } from "../../../../constants";
import { TOrderStatus } from "../../../../types/orderStatus";

interface IGetOrdersWorker {
  type: string;
  payload: {
    page: number;
    status: TOrderStatus;
  };
}

interface IParams {
  page: number;
  perPage: number;
  status?: TOrderStatus;
}

function* getOrdersWorker({ payload }: IGetOrdersWorker): SagaIterator {
  const { page, status } = payload;

  try {
    yield put(setOrdersLoading(true));

    const params: IParams = {
      page: page ?? 1,
      perPage: PRODUCTS_PER_PAGE,
    };

    status && (params.status = status);

    const { data, isError, message } = yield call(
      requester.get,
      links.ordersList,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveOrders(data));
    }
  } catch (error) {
    console.error("getOrdersWorker: ", error);
  } finally {
    yield put(setOrdersLoading(false));
  }
}

export function* getOrdersWatcher() {
  yield takeLatest("@saga/GET_ORDERS", getOrdersWorker);
}
