import {
  FC,
  ChangeEvent,
  RefObject,
  InputHTMLAttributes,
  useRef,
  memo,
} from "react";
import Checkbox from "../../checkbox/checkbox";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleFilterChange: (
    ref: RefObject<HTMLLIElement>,
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  defaultChecked?: boolean;
}

export const CheckboxItem: FC<IProps> = memo(
  ({ name, handleFilterChange, defaultChecked }) => {
    const checkboxRef = useRef<HTMLLIElement>(null);

    return (
      <li ref={checkboxRef} className="filter_item__checkbox">
        <Checkbox
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFilterChange(checkboxRef, e)
          }
          value={name}
          text={name}
          id={`filters_${name}`}
          defaultChecked={defaultChecked}
        />
      </li>
    );
  }
);
