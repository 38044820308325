import Axios from "axios";
import { IRequester } from ".";
import { IResponse } from "./IResponse";
import { DEFAULT_LANGUAGE, LOCAL_STORAGE } from "../../constants";
import {
  saveToken,
  setAuthType,
  setLoggedIn,
} from "../../store/user/repository/actions";
import { store } from "../../store/store";
import { createNotification } from "../../utils/createNotification";
import i18n from "../../locales";

class AxiosRequester implements IRequester {
  private static instance: AxiosRequester;

  constructor() {
    if (AxiosRequester.instance) {
      return AxiosRequester.instance;
    }
    AxiosRequester.instance = this;
  }

  private serverError = (status: number) => {
    if (status === 401 || status === 403) {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t("errorAuth"),
        type: "danger",
      });

      localStorage.removeItem(LOCAL_STORAGE.loggedIn);
      localStorage.removeItem(LOCAL_STORAGE.token);
      localStorage.removeItem(LOCAL_STORAGE.user);
      localStorage.removeItem(LOCAL_STORAGE.cart);
      store.dispatch(setLoggedIn(null));
      store.dispatch(setAuthType(null));
    }

    if (status >= 500) {
      console.error("Server error", status);
    }
  };

  postFormData = async (url: string, data: FormData) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        body: data,
      });
      const result = await response.json();
      return { data: result, status: response.status };
    } catch (error: any) {
      this.serverError(error?.response?.status);
      console.warn("AxiosRequester -> postFormData: ", error);
      return error?.response || {};
    }
  };

  delete = async (
    url: string,
    headers?: object,
    timeoutMS?: number,
    withCredentials?: boolean
  ): Promise<any> => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE.token);
      const config: any = {
        method: "DELETE",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "access-control-expose-headers": "Set-Cookie",
          language:
            localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
        },
        url,
        timeout: timeoutMS || 60000,
        withCredentials: withCredentials ?? false,
      };

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      headers && (config.headers = headers);

      const response = await Axios(config);
      return response;
    } catch (error: any) {
      this.serverError(error?.response?.status);
      console.warn("AxiosRequester -> delete: ", error);
      return error?.response || {};
    }
  };

  post = async (
    url: string,
    data?: object,
    headers?: object,
    timeoutMS?: number,
    withCredentials?: boolean
  ): Promise<any> => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE.token);
      const config: any = {
        method: "POST",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          language:
            localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
        },
        url,
        timeout: timeoutMS || 60000,
        withCredentials: withCredentials ?? false,
      };

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      headers && (config.headers = headers);
      data && (config.data = JSON.stringify(data));
      const response = await Axios(config);
      return response;
    } catch (error: any) {
      this.serverError(error?.response?.status);
      console.warn("AxiosRequester -> post: ", error);
      return error?.response || {};
    }
  };

  put = async (
    url: string,
    data?: object,
    headers?: object,
    timeoutMS?: number,
    withCredentials?: boolean
  ): Promise<any> => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE.token);
      const config: any = {
        method: "PUT",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          language:
            localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
        },
        url,
        timeout: timeoutMS || 60000,
        withCredentials: withCredentials ?? false,
      };

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      headers && (config.headers = headers);
      data && (config.data = JSON.stringify(data));
      const response = await Axios(config);
      return response;
    } catch (error: any) {
      this.serverError(error?.response?.status);
      console.warn("AxiosRequester -> put: ", error);
      return error?.response || {};
    }
  };

  get = async (
    url: string,
    params?: object,
    headers?: object,
    timeoutMS?: number,
    withCredentials?: boolean
  ): Promise<any> => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE.token);
      const config: any = {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          language:
            localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
        },
        url,
        timeout: timeoutMS || 60000,
        withCredentials: withCredentials ?? false,
      };

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      headers && (config.headers = headers);
      params && (config.params = params);
      const response = await Axios(config);
      const result = this.processingResponse(response);
      return result;
    } catch (error: any) {
      this.serverError(error?.response?.status);
      // console.warn("AxiosRequester -> get: ", error);
      return { isError: true, error, message: error.message };
    }
  };

  private processingResponse = (response: any): IResponse<any> => {
    let result: any = { isError: true, message: "" };
    if (response?.status < 400) {
      result = { isError: false, data: response.data, message: "" };
    } else if (response?.data?.error === "validation") {
      result = { isError: true, message: response?.data?.messages };
    } else {
      result = {
        isError: true,
        message: response?.data?.messages,
      };
    }
    return result;
  };
}

export const requester = new AxiosRequester();
