import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  savePromoProducts,
  setPromoProductsLoading,
  setHiddenPromoProductsLoading,
} from "../../repository/actions";
import { links } from "../../../../utils/links";
import { SLIDES_PER_PAGE } from "../../../../constants";
import { createNotification } from "../../../../utils/createNotification";

interface IGetPromoProductsWorker {
  type: string;
  payload: {
    page: number;
    noLoading?: boolean;
  };
}

function* getPromoProductsWorker({
  payload,
}: IGetPromoProductsWorker): SagaIterator {
  const { page, noLoading } = payload;

  try {
    const params = {
      perPage: SLIDES_PER_PAGE,
      page: page ?? 1,
    };

    if (noLoading) {
      yield put(setHiddenPromoProductsLoading(true));
    } else {
      yield put(setPromoProductsLoading(true));
    }
    const { data, isError, message } = yield call(
      requester.get,
      links.promoList,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(savePromoProducts(data));
    }
  } catch (error) {
    console.error("getPromoProductsWorker: ", error);
  } finally {
    if (noLoading) {
      yield put(setHiddenPromoProductsLoading(false));
    } else {
      yield put(setPromoProductsLoading(false));
    }
  }
}

export function* getPromoProductsWatcher() {
  yield takeLatest("@saga/GET_PROMO_PRODUCTS", getPromoProductsWorker);
}
