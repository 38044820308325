import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SinglePromotionSection from "../../ui-kit/singlePromotionSection/singlePromotionSection";
import { getSinglePromo } from "../../store/promo/useCases/getSinglePromo/action";

const SinglePromotionPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSinglePromo({ id, isSavePrice: true }));
    }
  }, [id]);

  return <SinglePromotionSection />;
};

export default SinglePromotionPage;