import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveProducts, saveProductsPrices, setProductsFinish, setProductsLoading, setHiddenProductsLoading } from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";
import { IProductSettings } from "../../repository/IProductSettings";
import { PRODUCTS_PER_PAGE, REGULARS } from "../../../../constants";

interface IProductWorker {
  type: string;
  payload: {
    payload: IProductSettings;
    name: string;
  };
};

function* getCategoryProductsWorker({ payload }: IProductWorker): SagaIterator {
  const { id = 0, filter = [], priceMin, priceMax, sortBy = [], page = 1, perPage = PRODUCTS_PER_PAGE, slider, productGroupId, noLoading, isSavePrice, isPromo } = payload.payload;
 
  try {
    if (noLoading) {
      yield put(setHiddenProductsLoading(true));
    } else {
      yield put(setProductsLoading(true));
    }

    const { name } = payload;
    const sortByArr = slider ? [] : [{ field: "isAvailable", order: "DESC" }, ...sortBy];

    const params: any = { page, perPage, sortBy: JSON.stringify(sortByArr) };

    if (name) {
      if (REGULARS.isNumbersOnly.test(name)) {
        params.code1c = name;
      } else {
        params.name = name;
      }
    } else if (slider) {
      slider === "sale" && (params.isSale = true);
      slider === "promo" && (params.isPromo = true);
      slider === "group" && (params.productGroupId = productGroupId);
      id && (params.categoryId = id);
    } else {
      id && (params.categoryId = id);
      // (id && !isPromo) && (params.categoryId = id);
      // (id && isPromo) && (params.promoId = id);

      priceMin && (params.priceMin = priceMin);
      priceMax && (params.priceMax = priceMax);
      if (filter && filter.length > 0) {
        params.filter = JSON.stringify(filter);
      }
    }

    const { data, isError, message } = yield call(requester.get, links.product, params);
    if (isError) {
      createNotification({ title: i18n.t("error"), message, type: "danger", });
    } else {
      yield put(saveProducts(data));
      if (isSavePrice) {
        yield put(saveProductsPrices(data.prices));
      }
    }
  } catch (error) {
    console.error("getCategoryProductsWorker: ", error);
  } finally {
    if (noLoading) {
      yield put(setHiddenProductsLoading(false));
    } else {
      yield put(setProductsLoading(false));
    }

    if (isSavePrice) {
      yield put(setProductsFinish(true));
    }
  }
};

export function* getProductsWatcher() {
  yield takeLatest("@saga/GET_CATEGORY_PRODUCTS", getCategoryProductsWorker);
};
