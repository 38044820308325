import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNewsPage } from "../../store/news/repository/selectors";
import { selectCurrentLang } from "../../store/language/repository/selectors";
import { getNews } from "../../store/news/useCases/getNews/action";
import NewsSection from "../../ui-kit/newsSection/newsSection";

const NewsPage = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectNewsPage);
  const lang = useSelector(selectCurrentLang);

  useEffect(() => {
    dispatch(getNews(currentPage));
  }, [lang]);

  return <NewsSection />;
};

export default NewsPage;
