import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../locales";
import { PRODUCTS_PER_PAGE } from "../../constants";
import { getNotifications } from "../../store/notifications/useCases/getNotifications/action";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import UnregisteredMessage from "../unregisteredMessage/unregisteredMessage";
import { selectNotifications } from "../../store/notifications/repository/selectors";
import NotificationItem from "./ui/notificationItem";
import Pagination from "../pagination/pagination";
import "./notificationSection.scss";
import { IoCheckmarkDone } from "react-icons/io5";
import { setNotificationsReadAll } from "../../store/notifications/useCases/setNotificationsReadAll/action";
import Popup from "../popup/popup";
import ConfirmPopup from "../confirmPopup/confirmPopup";

const NotificationSection = () => {
  const [activeItem, setActiveItem] = useState(1);

  const { data: notifications, count } = useSelector(selectNotifications);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const dispatch = useDispatch();
  
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setConfirmOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setConfirmOpen(false);
  }, []); 

  const clearAll = useCallback(() => {
    dispatch(setNotificationsReadAll({activeItem}));
    // setActiveItem(1);
    // dispatch(getNotifications(activeItem));
    handleClose();
  }, [activeItem]);

  const paginationClick = (page: number) => {
    dispatch(getNotifications(page));
    setActiveItem(page);
  };

  if (isLoggedIn !== "true") return <UnregisteredMessage />;

  return (
    <>
      <section className="notifications">
        <div className="notifications__body">
          <div className="notifications__head">
            <button className="notifications__clear-button" onClick={handleOpen} ref={buttonRef}>
              <IoCheckmarkDone size={25} />
            </button>
          </div>
          {notifications.length ? (
            <div className="notifications__body">
              <ul className="notifications__list">
                {notifications.map((item) => (
                  <li className="notifications__item" key={item.id}>
                    <NotificationItem {...item} />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="notifications__message">
              <p>{i18n.t("emptyNotifications")}</p>
            </div>
          )}
        </div>
        {count > PRODUCTS_PER_PAGE && (
          <Pagination
            pagesCount={count}
            onClick={paginationClick}
            activeItem={activeItem}
          />
        )}
      </section>
      <div>
        <Popup
          isOpen={isConfirmOpen}
          handleClose={handleClose}
          triggerRef={buttonRef}
          size="xxs"
        >
          <ConfirmPopup
            title={i18n.t("agreeClearAllMessages")}
            onConfirm={clearAll}
            onDecline={handleClose}
          />
        </Popup>
      </div>
    </>
  );
};

export default NotificationSection;
