import i18n from "../../locales";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setAuthPopupOpen } from "../../store/user/repository/actions";
import { addProductReview } from "../../store/reviews/useCases/addProductReview/action";
import { selectProductReviewsPopupOpen } from "../../store/reviews/repository/selectors";
import { setProductReviewsPopupOpen } from "../../store/reviews/repository/actions";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import TextareaField from "../textareaField/textareaField";
import RatingField from "../ratingField/ratingField";
import Popup from "../popup/popup";
import "./reviewsForm.scss";

interface IFromData {
  rating: number;
  comment: string;
}

const ReviewsForm = () => {
  const [resetKey, setResetKey] = useState(0);
  const [isInfoPopupOpen, setInfoPopupOpen] = useState(false);
  const { id } = useParams();
  const isPopupOpen = useSelector(selectProductReviewsPopupOpen);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset } = useForm<IFromData>();

  const onSubmit = ({ rating, comment }: IFromData) => {
    if (isLoggedIn === "true") {
      dispatch(addProductReview({ productId: Number(id), rating, comment }));
      reset();
      setResetKey((prev) => prev + 1);
    } else {
      setInfoPopupOpen(true);
    }
  };

  const handleInfoPopupClose = () => {
    setInfoPopupOpen(false);
  };

  const handlePopupClose = () => {
    dispatch(setProductReviewsPopupOpen(false));
  };

  const handleAuthOpen = () => {
    handleInfoPopupClose();
    dispatch(setAuthPopupOpen(true));
  };

  return (
    <form action="#" onSubmit={handleSubmit(onSubmit)} className="reviews_form">
      <div className="reviews_form__wrap">
        <div className="reviews_form__row">
          <div className="reviews_form__column">
            <RatingField
              key={resetKey}
              label={`${i18n.t("mark")}:`}
              required={true}
              setValue={setValue}
            />
          </div>
          <div className="reviews_form__column reviews_form__column--full_mod">
            <TextareaField
              label={`${i18n.t("message")}:`}
              id="review_message"
              register={register("comment")}
              required={true}
              placeholder={`${i18n.t("reviewMessage")}`}
            />
          </div>
        </div>
      </div>
      <div className="reviews_form__bottom">
        <button type="submit" className="reviews_form__button">
          <span>{i18n.t("leaveFeedback")}</span>
        </button>
      </div>
      {isPopupOpen && (
        <Popup size="xxs" isOpen={isPopupOpen} handleClose={handlePopupClose}>
          <div className="reviews_form__content">
            <div className="reviews_form__title">{i18n.t("reviewSent")}</div>
            <div className="reviews_form__text">{i18n.t("reviewSentInfo")}</div>
            <button
              type="button"
              onClick={handlePopupClose}
              className="reviews_form__popup_button"
            >
              {i18n.t("ok")}
            </button>
          </div>
        </Popup>
      )}
      {isInfoPopupOpen && (
        <Popup
          size="xxs"
          isOpen={isInfoPopupOpen}
          handleClose={handleInfoPopupClose}
        >
          <div className="reviews_form__content">
            <div className="reviews_form__title">
              {i18n.t("loginOrRegisterTitle")}
            </div>
            <div className="reviews_form__wrapper">
              <button
                type="button"
                onClick={handleAuthOpen}
                className="reviews_form__popup_button reviews_form__popup_button--half_mod"
              >
                {i18n.t("ok")}
              </button>
              <button
                type="button"
                onClick={handleInfoPopupClose}
                className="reviews_form__popup_button reviews_form__popup_button--primary_mod reviews_form__popup_button--half_mod"
              >
                <span>{i18n.t("cancel")}</span>
              </button>
            </div>
          </div>
        </Popup>
      )}
    </form>
  );
};

export default ReviewsForm;
