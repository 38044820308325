import { useState, useLayoutEffect } from "react";

const useScrollbarWidth = (): number | null => {
  const [scrollbarWidth, setScrollbarWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    const updateScrollbarWidth = () => {
      const div = document.createElement("div");
      div.style.overflowY = "scroll";
      div.style.width = "5rem";
      div.style.height = "5rem";
      document.body.appendChild(div);

      const scrollbarWidth = div.offsetWidth - div.clientWidth;
      setScrollbarWidth(scrollbarWidth);

      document.body.removeChild(div);
    };

    window.addEventListener("resize", updateScrollbarWidth);
    updateScrollbarWidth();

    return () => {
      window.removeEventListener("resize", updateScrollbarWidth);
    };
  }, []);

  return scrollbarWidth;
};

export default useScrollbarWidth;
