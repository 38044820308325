import { useState, useRef, FC, ReactNode } from "react";
import classNames from "classnames";
import { useMedia } from "../../customHooks";
import { transformValueToVH } from "../../utils/calculateHeight";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./footerSpoiler.scss";

export interface ISpoilerLink {
  text: string;
  href: string;
}

interface IProps {
  headIcon: ReactNode;
  headText: ReactNode;
  linksList: ISpoilerLink[];
  columnMod?: boolean;
}

const FooterSpoiler: FC<IProps> = ({
  headIcon,
  headText,
  linksList,
  columnMod,
}) => {
  const [isOpen, setOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia();

  const handleClick = () => {
    if (isMobile) setOpen((open) => !open);
  };

  const currentHeight = isOpen ? contentRef.current?.scrollHeight : 0;
  const maxHeight = isMobile
    ? {
        maxHeight: `${
          currentHeight !== undefined && transformValueToVH(currentHeight)
        }`,
      }
    : undefined;

  const spoilerClasses = classNames("footer_spoiler", {
    "footer_spoiler--active_state": isOpen,
  });

  return (
    <div className={spoilerClasses}>
      <div onClick={handleClick} className="footer_spoiler__head">
        <div className="footer_spoiler__icon font_icon">{headIcon}</div>
        <div className="footer_spoiler__text">{headText}</div>
        {isMobile && (
          <div className="footer_spoiler__arrow font_icon">
            <FiChevronDown />
          </div>
        )}
      </div>
      <div
        ref={isMobile ? contentRef : null}
        style={maxHeight}
        className="footer_spoiler__content"
      >
        <ul
          className={classNames("footer_spoiler__list", {
            "footer_spoiler__list--columns_mod": columnMod,
          })}
        >
          {linksList.map(({ text, href }, index: number) => {
            return (
              <li key={index} className="footer_spoiler__item">
                <Link to={href} className="footer_spoiler__link">
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FooterSpoiler;
