import i18n from "../../locales";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findPercentFromPrice, TPromo } from "../../utils/findPercentFromPrice";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { updateCartProduct } from "../../store/orders/useCases/updateCartProduct/action";
import { deleteCartProduct } from "../../store/orders/useCases/deleteCartProduct/action";
import { setCartChange } from "../../store/orders/repository/actions";
import { LOCAL_STORAGE, ROUTES } from "../../constants";
import { ICartProduct } from "../../store/orders/repository/ICartProduct";
import { IProduct } from "../../store/products/repository/IProduct";
import { Link } from "react-router-dom";
import Image from "../image/image";
import Quantity from "../quantity/quantity";
import Price from "../price/price";
import { TiDelete } from "react-icons/ti";
import "./cartProduct.scss";

interface IProps extends IProduct {
  initialAmount: number;
  isPrecart?: boolean;
  handleClose?: () => void;
};

export type TCartAction = "del" | "inc" | "dec" | "chg";

const CartProduct = ({ id, name, price, images, code1c, minimalCount, initialAmount, promo, currency, oldPrice, isPrecart, handleClose }: IProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);

  const [isSaleApply, setisSaleApply] = useState<boolean>(false);
  const [realPrice, setRealPrice] = useState<number>(price);
  const [realOldPrice, setRealOldPrice] = useState<number | undefined>(
    undefined
  );

  const [amount, setAmount] = useState<number>(initialAmount);
  const isPromoExist = promo && promo.amount && promo.percents;

  const cartAction = (type: TCartAction, products: string, val?: number) => {
    const parsedProducts = JSON.parse(products);
    const productIndex = parsedProducts.findIndex(
      ({ product }: ICartProduct) => product.id === id
    );

    if (productIndex !== -1) {
      const key =
        type === "del"
          ? id * -1
          : `chng${id}${parsedProducts[productIndex].amount}`;

      switch (type) {
        case "del":
          parsedProducts.splice(productIndex, 1);
          break;

        case "chg":
          parsedProducts[productIndex].amount = val;
          break;

        default:
          break;
      }

      localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify(parsedProducts));
      dispatch(setCartChange(key));
    }
  };

  const onDelete = () => {
    if (isLoggedIn === "true") {
      dispatch(deleteCartProduct(id));
      isPrecart && handleClose?.();
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);

      if (products) {
        cartAction("del", products);
      }
    }
  };

  const onIncrease = () => {
    const newValue = amount + 1;
    if (isLoggedIn === "true") {
      dispatch(updateCartProduct(id, newValue));
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);

      if (products) {
        cartAction("chg", products, newValue);
      }
    }

    setAmount(newValue);
  };

  const onDecrease = () => {
    const newValue = amount - 1;

    if (isLoggedIn === "true") {
      dispatch(updateCartProduct(id, newValue));
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);

      if (products) {
        cartAction("chg", products, newValue);
      }
    }

    setAmount(newValue);
  };

  const onChange = (newValue: number) => {
    if (isLoggedIn === "true") {
      dispatch(updateCartProduct(id, newValue));
    } else {
      const products = localStorage.getItem(LOCAL_STORAGE.cart);

      if (products) {
        cartAction("chg", products, newValue);
      }
    }

    setAmount(newValue);
  };

  const applyPromo = (type: TPromo) => {
    if (!isPromoExist) return;

    const products = localStorage.getItem(LOCAL_STORAGE.cart);
    if (!products) return;

    const parsedProducts = JSON.parse(products);
    const productIndex = parsedProducts.findIndex(
      ({ product }: ICartProduct) => product.id === id
    );

    if (productIndex === -1) return;

    const promoPrice = findPercentFromPrice(price, promo.percents, type);
    setRealPrice(promoPrice);
  };

  const checkPromo = () => {
    if (!isPromoExist) return;

    if (amount >= promo.amount) {
      if (isSaleApply) return;
      applyPromo("add");
      setisSaleApply(true);
    } else {
      if (!isSaleApply) return;
      applyPromo("del");
      setisSaleApply(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn === "true") return;
    checkPromo();
  }, [id, amount, isSaleApply, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn !== "true") return;
    setRealPrice(price);
  }, [price]);

  useEffect(() => {
    if (isLoggedIn === "true") {
      setRealOldPrice(oldPrice);
    } else {
      setRealOldPrice(price);
    }
  }, [oldPrice, price]);

  return (
    <li className="cart_product">
      <div className="cart_product__col">
        <div className="cart_product__descr">
          <Link
            to={`${ROUTES.SingleProduct}/${id}`}
            className="cart_product__image"
          >
            {images && images[0] && <Image src={images[0].fileUrl} />}
          </Link>
          <div className="cart_product__wrapper">
            <Link
              to={`${ROUTES.SingleProduct}/${id}`}
              className="cart_product__name"
            >
              {name}
            </Link>
            {code1c && (
              <div className="cart_product__code">
                {i18n.t("productCode")}: <strong>{code1c}</strong>
              </div>
            )}
            {promo?.title && (
              <div className="cart_product__promo_title">
                {i18n.t("promo")}: {promo?.title}
              </div>
            )}
            {promo?.description && (
              <div className="cart_product__promo_descr">
                {promo?.description}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="cart_product__col">
        <Price 
          currency={currency}
          size="xs"
          oldPrice={realOldPrice}
          price={realPrice}
          promo={promo}
        />
      </div>
      <div className="cart_product__col">
        <Quantity
          handleIncrease={onIncrease}
          handleDecrease={onDecrease}
          onChange={onChange}
          value={amount}
          minimalCount={minimalCount ?? 1}
          key={id}
        />
      </div>
      <div className="cart_product__col">
        <button
          onClick={onDelete}
          aria-label="Delete product"
          className="cart__delete font_icon"
        >
          <TiDelete />
        </button>
      </div>
    </li>
  );
};

export default CartProduct;
