import { IFavoritesResponse } from "./IFavoritesResponse";

interface IInitialState {
  favoritesProducts: IFavoritesResponse;
  isFavoritesProductsLoading: boolean;
  favoritesPage: number;
  unlikedProducts: number[];
  likedProducts: number[];
}

const initialState: IInitialState = {
  favoritesProducts: { data: [], count: 0 },
  isFavoritesProductsLoading: false,
  favoritesPage: 1,
  unlikedProducts: [],
  likedProducts: [],
};

export function favoritesReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_FAVORITES_PRODUCTS":
      return { ...state, favoritesProducts: action.payload };

    case "SAVE_FAVORITES_PAGE":
      return { ...state, favoritesPage: action.payload };

    case "SAVE_UNLIKED_PRODUCTS":
      return { ...state, unlikedProducts: action.payload };

    case "SAVE_LIKED_PRODUCTS":
      return { ...state, likedProducts: action.payload };

    case "SET_FAVORITES_PRODUCTS_LOADING":
      return { ...state, isFavoritesProductsLoading: action.payload };

    default:
      return state;
  }
}
