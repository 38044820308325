import { FC, ReactNode } from "react";
import "./label.scss";

interface IProps {
  children: ReactNode;
  id?: string;
  required?: boolean;
}

const Label: FC<IProps> = ({ id, children, required }) => {
  return (
    <label htmlFor={id} className="label">
      {required && <span className="label__sign">*</span>}
      {children}
    </label>
  );
};

export default Label;
