import i18n from "../../locales";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectWaitingList } from "../../store/waitingList/repository/selectors";
import { getWaitingList } from "../../store/waitingList/useCases/getWaitingList/action";
import { deleteFromWaiting } from "../../store/waitingList/useCases/deleteWaitingProduct/action";
import { PRODUCTS_PER_PAGE } from "../../constants";
import UnregisteredMessage from "../unregisteredMessage/unregisteredMessage";
import ProductList from "../productList/productList";
import Pagination from "../pagination/pagination";
import "./notifyProducts.scss";
import { IProduct } from "../../store/products/repository/IProduct";

const NotifyProducts = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const [activeItem, setActiveItem] = useState(1);

  const { data, count } = useSelector(selectWaitingList);
  const [waitingProducts, setWaitingProducts] = useState<IProduct[]>([]);

  const paginationClick = (page: number) => {
    dispatch(getWaitingList(page));
    setActiveItem(page);
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFromWaiting(id, activeItem));
    setWaitingProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id)
    );
  };

  useEffect(() => {
    if (!data.length) return;

    const transformedData = data.map((item) => ({ ...item.product }));
    setWaitingProducts(transformedData);
  }, [data]);

  if (isLoggedIn !== "true") return <UnregisteredMessage />;

  return (
    <section className="notify_products">
      <div className="notify_products__body">
        {waitingProducts.length ? (
          <ProductList onDelete={handleDelete} products={waitingProducts} />
        ) : (
          <div className="notify_products__message">
            <p>{i18n.t("emptyNotifyProducts")}</p>
          </div>
        )}
      </div>
      {count > PRODUCTS_PER_PAGE && (
        <Pagination
          pagesCount={count}
          onClick={paginationClick}
          activeItem={activeItem}
        />
      )}
    </section>
  );
};

export default NotifyProducts;
