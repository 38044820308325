import { DOMAIN } from "../constants";

const domain = DOMAIN;
const product = "product/";
const productChar = "admin/product-characteristic/";
const productGroup = "product-group/";
const productReview = "product-review/";
const promo = "promo/";
const auth = "auth/";
const authOrder = "auth-order/";
const news = "news-rating/";
const favorites = "favorites/";
const order = "product-order/";
const banner = "banner/";
const promocode = "promo-code/";
const message = "user-message/";
const waiting = "waiting-list/";
const feedback = "feedback/";
const excel = "excel/";
const xml = "xml/";

export const links = {
  languages: domain + "localization/language",
  category: domain + "category",

  product: domain + productChar + "get-products",
  filters: domain + productChar + "get-filters",
  singleProduct: domain + productChar + "get-product",
  productDescription: domain + product + "get-description",
  productCharacteristics: domain + product + "get-characteristics",
  productReviews: domain + productReview + "get-product-review-list",
  addReview: domain + productReview + "add-review",
  saleProducts: domain + product + "get-sale-products",

  groupList: domain + productGroup + "get-groups-list",

  promoList: domain + promo + "get-promo-slider",
  promotionList: domain + promo,
  singlePromo: domain + promo,

  login: domain + authOrder + "login",
  register: domain + auth + "registration",
  restorePassEmail: domain + auth + "restore-password-email",
  restorePassPhone: domain + auth + "restore-password-phone",
  confirmPhone: domain + auth + "confirm-phone-code",
  confirmEmail: domain + auth + "confirm-restore-email-code",
  confirmPassword: domain + auth + "confirm-password",

  user: domain + "user",

  news: domain + news + "get-list",
  singleNews: domain + news + "get-news",
  addRating: domain + news + "add-rating",

  favoritesList: domain + favorites + "get-list",
  addToFavorite: domain + favorites + "add-product",
  deleteFromFavorite: domain + favorites + "delete-product",

  ordersList: domain + order + "get-orders-list",
  addProductToOrder: domain + order + "add-product",
  createOrder: domain + order + "create-order",
  deleteFromOrder: domain + order + "delete",
  deleteAllFromOrder: domain + order + "delete-all",
  updateProductInCart: domain + order + "update-cart-product",
  getCart: domain + order + "get-cart",
  fillCart: domain + order + "fill-cart",

  getBanners: domain + banner + "get",

  activatePromocode: domain + promocode + "activate",

  notifications: domain + message + "get-list",
  notificationRead: domain + message + "read",
  notificationReadAll: domain + message + "read/all",

  getWaitingList: domain + waiting + "get-list",
  addToWaiting: domain + waiting + "add-product",
  deleteFromWaiting: domain + waiting + "delete-product",

  createFeedback: domain + feedback + "create",

  getPriceList: domain + excel + "products-extended-price-list",
  getShortPriceList: domain + excel + "products-small-price-list",

  getProductsFile: domain + xml + "products-file",
};
