import { useLayoutEffect, useRef } from "react";

const useLatest = (value: () => void) => {
  const valueRef = useRef(value);

  useLayoutEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
};

export default useLatest;
