import { IPromoCodeResponse } from "./IPromoCodeResponse";

interface IInitialState {
  response: IPromoCodeResponse | null;
}

const initialState: IInitialState = {
  response: null,
};

export function promoCodeReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PROMO_CODE_RESPONSE":
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
