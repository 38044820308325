import i18n from "../../locales";
import { IProduct } from "../../store/products/repository/IProduct";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProducts,
  selectProductsLoading,
  selectSaleProducts,
  selectSaleProductsLoading,
} from "../../store/products/repository/selectors";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { getSaleProducts } from "../../store/products/useCases/getSaleProducts/action";
import { SLIDES_PER_PAGE } from "../../constants";
import ProductsTabs from "../../ui-kit/productsTabs/productsTabs";

const SaleProducts = () => {
  const [saleProducts, setSaleProducts] = useState<IProduct[]>([]);
  const [categoryId, setCategoryId] = useState<number>(-1);

  const dispatch = useDispatch();
  const { categories, products } = useSelector(selectSaleProducts);
  const { data } = useSelector(selectProducts);
  const isSaleProductsLoading = useSelector(selectSaleProductsLoading);
  const isCategoryProductsLoading = useSelector(selectProductsLoading);

  const handleTabClick = (id: number) => {
    setCategoryId(id);

    if (id === -1) {
      dispatch(getSaleProducts());
    } else {
      dispatch(
        getCategoryProducts({
          id,
          perPage: SLIDES_PER_PAGE,
          slider: "sale",
        })
      );
    }
  };

  useEffect(() => {
    if (categoryId !== -1) {
      setSaleProducts(data);
    } else {
      setSaleProducts(products);
    }
  }, [data, products]);

  return (
    <ProductsTabs
      mod="sale"
      handleTabClick={handleTabClick}
      isLoading={isSaleProductsLoading || isCategoryProductsLoading}
      title={i18n.t("sale")}
      products={saleProducts}
      categories={categories}
    />
  );
};

export default SaleProducts;
