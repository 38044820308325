import i18n from "../../locales";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import {
  selectFavoritesPage,
  selectFavoritesProducts,
  selectFavoritesProductsLoading,
  selectUnlikedProducts,
} from "../../store/favorites/repository/selectors";
import { getFavoritesProducts } from "../../store/favorites/useCases/getFavorites/action";
import { saveFavoritesPage } from "../../store/favorites/repository/actions";
import { PRODUCTS_PER_PAGE } from "../../constants";
import Loader from "../loader/loader";
import ProductList from "../productList/productList";
import UnregisteredMessage from "../unregisteredMessage/unregisteredMessage";
import Pagination from "../pagination/pagination";
import "./favoritesSection.scss";

const FavoritesSection = () => {
  const dispatch = useDispatch();
  const { data, count } = useSelector(selectFavoritesProducts);
  const isLoading = useSelector(selectFavoritesProductsLoading);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const currentPage = useSelector(selectFavoritesPage);
  const unlikedProducts = useSelector(selectUnlikedProducts);

  const [activeItem, setActiveItem] = useState(currentPage ?? 1);

  if (isLoggedIn !== "true") return <UnregisteredMessage />;

  const filteredProducts =
    unlikedProducts.length > 0
      ? data.filter((product) => !unlikedProducts.includes(product.id))
      : data;

  const paginationClick = (page: number) => {
    dispatch(getFavoritesProducts(page));
    dispatch(saveFavoritesPage(page));
    setActiveItem(page);
  };

  return (
    <div className="favorites_section">
      {isLoading && <Loader />}
      {filteredProducts.length ? (
        <div className="favorites_section__body">
          <ProductList products={filteredProducts} />
        </div>
      ) : (
        <div className="favorites_section__message">
          <p>{i18n.t("emptyFavorites")}</p>
          <p>{i18n.t("emptyFavorites2")}</p>
        </div>
      )}
      {count > PRODUCTS_PER_PAGE && (
        <Pagination
          pagesCount={count}
          onClick={paginationClick}
          activeItem={activeItem}
        />
      )}
    </div>
  );
};

export default FavoritesSection;
