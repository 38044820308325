import i18n from "../../locales";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCatalog } from "../../store/catalog/repository/selectors";
import { ROUTES } from "../../constants";
import Payments from "../payments/payments";
import AppButtons from "../appButtons/appButtons";
import FooterSpoiler, { ISpoilerLink } from "../footerSpoiler/footerSpoiler";
import {
  AiFillInfoCircle,
  AiFillAppstore,
  AiFillQuestionCircle,
} from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import "./footer.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  const catalog = useSelector(selectCatalog);
  const [filteredCatalog, setFilteredCatlog] = useState<ISpoilerLink[]>([]);

  const infoList = [
    {
      text: i18n.t("news"),
      href: ROUTES.NewsPage,
    },
    {
      text: i18n.t("warrantyConditions"),
      href: ROUTES.WarrantyConditions,
    },
    {
      text: i18n.t("contactUs"),
      href: ROUTES.ContactsPage,
    },
  ];

  useEffect(() => {
    const filteredCatalog = catalog.slice().sort((a, b) => {
      if (a.sortNumber === undefined && b.sortNumber === undefined) {
        return 0;
      }
      if (a.sortNumber === undefined) {
        return 1;
      }
      if (b.sortNumber === undefined) {
        return -1;
      }
      return a.sortNumber - b.sortNumber;
    });

    const links = filteredCatalog.map(({ id, name, childCategories }) => ({
      text: name,
      href: !!childCategories?.length
        ? `${ROUTES.Categories}/${id}`
        : `${ROUTES.ProductList}/${id}`,
    }));

    setFilteredCatlog(links);
  }, [catalog]);

  return (
    <footer className="footer">
      <div className="footer_in">
        <div className="footer__body">
          <div className="footer__row">
            <div className="footer__column">
              <FooterSpoiler
                headIcon={<AiFillInfoCircle />}
                headText={i18n.t("info")}
                linksList={infoList}
              />
            </div>
            <div className="footer__column">
              <FooterSpoiler
                headIcon={<AiFillAppstore />}
                headText={i18n.t("catalog")}
                linksList={filteredCatalog}
                columnMod
              />
            </div>
            <div className="footer__column">
              <h5 className="footer__title">{i18n.t("followUsOnSocial")}:</h5>
              <div className="footer__buttons">
                <AppButtons />
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__payments">
            <Payments />
          </div>
          <div className="footer__copy">
            <p>
              Apulse. {i18n.t("allRightsReserved")} © {year}
            </p>
            <a
              href="https://t.me/maksimenkooleg"
              target="_blank"
              rel="noreferrer"
            >
              <span>{i18n.t("developed")}: NLtDev</span>
              <span className="footer__icon font_icon">
                <BsTelegram />
              </span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
