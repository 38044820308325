import { useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBanners } from "../../store/banners/repository/selectors";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import Section from "../section/section";
import FullScreenSlide from "../fullScreenSlide/fullScreenSlide";
import SwiperArrow from "../swiperArrow/swiperArrow";
import "./fullScreenSlider.scss";
import "swiper/css";

const FullScreenSlider = () => {
  const { data } = useSelector(selectBanners);

  SwiperCore.use([Navigation, Pagination, Autoplay]);

  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const swiperProps = {
    slidesPerView: 1,
    initialSlide: 1,
    pagination: {
      clickable: true,
    },
    loop: true,
    autoplay: {
      delay: 5000,
    },
    speed: 800,
    onInit: (swiper: SwiperCore) => {
      if (
        swiper.params.navigation &&
        typeof swiper.params.navigation !== "boolean"
      ) {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
      }
    },
  };

  const sortedSlides = useMemo(
    () => data.slice().sort((a, b) => a.sortNumber - b.sortNumber),
    [data]
  );

  if (data.length < 0) return null;

  return (
    <Section noMaxWidth className="hero_slider">
      <Swiper className="hero_slider__body" {...swiperProps}>
        {sortedSlides.map((slide) => {
          return (
            <SwiperSlide key={slide.id} className="hero_slider__slide">
              <FullScreenSlide {...slide} />
            </SwiperSlide>
          );
        })}
        <SwiperArrow
          arrowRef={prevRef}
          classes="hero_slider__arrow hero_slider__arrow--prev_mod"
        />
        <SwiperArrow
          arrowRef={nextRef}
          classes="hero_slider__arrow hero_slider__arrow--next_mod"
        />
      </Swiper>
    </Section>
  );
};

export default FullScreenSlider;
