import NavBar from "../navBar/navBar";
import Social from "../social/social";
import ThemeButton from "../themeButton/themeButton";
import CatalogButton from "../catalogButton/catalogButton";
import "./mobMenu.scss";

const MobMenu = () => {
  return (
    <div className="menu">
      <div className="menu__body">
        <div className="menu__wrap">
          <div className="menu__catalog">
            <CatalogButton megaMenu={true} />
          </div>
          <div className="menu__nav">
            <NavBar />
          </div>
        </div>
        <div className="menu__theme_btn">
          <ThemeButton />
        </div>
      </div>
    </div>
  );
};

export default MobMenu;
