import { ICatalog } from "../../store/catalog/repository/ICatalog";
import Section from "../section/section";
import CategoryCard from "../categoryCard/categoryCard";
import "./categoriesList.scss";

const CategoriesList = ({ childCategories, name, description }: ICatalog) => {
  return (
    <Section className="categories_list">
      {name && <h1 className="categories_list__title">{name}</h1>}
      <div className="categories_list__wrap">
        <ul className="categories_list__body">
          {childCategories &&
            childCategories.map((card) => (
              <li key={card.id} className="categories_list__item">
                <CategoryCard {...card} />
              </li>
            ))}
        </ul>
      </div>
      {description && description !== "" && (
        <div className="categories_list__descr">{description}</div>
      )}
    </Section>
  );
};

export default CategoriesList;
