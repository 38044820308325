import { FC } from "react";
import { useSelector } from "react-redux";
import i18n from "../../locales";
import { selectCatalog } from "../../store/catalog/repository/selectors";
import { AiFillAppstore } from "react-icons/ai";
import MegaItem from "../megaItem/megaItem";
import CatalogTrigger from "../catalogTrigger/catalogTrigger";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import "./catalogButton.scss";

interface IProps {
  megaMenu?: boolean;
}

const CatalogButton: FC<IProps> = ({ megaMenu }) => {
  const catalog: ICatalog[] = useSelector(selectCatalog);

  const props = {
    item: { id: -1, name: i18n.t("productsCatalog"), childCategories: catalog },
    Icon: AiFillAppstore,
  };

  return (
    <div className="catalog_button">
      {megaMenu ? <MegaItem {...props} /> : <CatalogTrigger {...props} />}
    </div>
  );
};

export default CatalogButton;
