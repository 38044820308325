import i18n from "../../locales";
import { getCountries, getStates } from "country-state-picker";
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateUser } from "../../store/user/useCases/updateUser/actions";
import { setConfirmEmailPopupOpen } from "../../store/user/repository/actions";
import {
  selecUpdateUserLoading,
  selectConfirmEmailPopupOpen,
} from "../../store/user/repository/selectors";
import { createNotification } from "../../utils/createNotification";
import { selectUser } from "../../store/user/repository/selectors";
import { IUpdateData } from "../../store/user/repository/IUpdateData";
import { FormLayout, FormLayoutItem } from "../formLayout";
import InputField from "../inputField/inputField";
import SelectField from "../selectField/selectField";
import Loader from "../loader/loader";
import Popup from "../popup/popup";
import "./editProfileForm.scss";

interface ISelectOption {
  label: string;
  value: string;
}

interface ICountryOption {
  name: string;
  code: string;
  dial_code: string;
}
interface IRegionOption {
  label: string;
  value: string;
}

const EditProfileForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selecUpdateUserLoading);
  const isInfoPopupOpen = useSelector(selectConfirmEmailPopupOpen);
  const [errorField, setErrorField] = useState<string | null>(null);
  const [country, setCountry] = useState<ISelectOption | null>(null);
  const [defaultCountry, setDefaultCountry] = useState<ISelectOption | null>(
    null
  );
  const [cityKey, setCityKey] = useState<number>(0);
  const [defaultCity, setDefaultCity] = useState<ISelectOption | undefined>(
    undefined
  );
  const [regionData, setRegionData] = useState<IRegionOption[]>([]);
  const { handleSubmit, register, setValue } = useForm<IUpdateData>();

  const countryOptions = useMemo(() => {
    return getCountries().map(({ name, code }: ICountryOption) => ({
      label: name,
      value: code,
    }));
  }, []);

  const handleCountrySelect = (
    value: ISelectOption,
    pullFromUser?: boolean
  ) => {
    setValue("country", value.label);
    setCountry(value);

    const regions = getStates(value.value);
    const regionOptions = regions.map((region: string) => {
      return {
        label: region,
        value: region,
      };
    });

    setRegionData(regionOptions);

    setDefaultCity(undefined);
    handleRegionSelect(undefined);
    setCityKey((prev) => prev + 1);

    if (pullFromUser && user) {
      const userCity = user.state;

      const currentCity = regionOptions.find(
        ({ label }: ISelectOption) => label === userCity
      );

      if (currentCity) {
        setDefaultCity(currentCity);
        handleRegionSelect(currentCity.label);
      }
    }
  };
  const handleRegionSelect = (value: string | undefined) => {
    setValue("city", value);
  };

  const handlePopupClose = () => {
    dispatch(setConfirmEmailPopupOpen(false));
  };

  useEffect(() => {
    if (!user) return;

    const userCountry = user.country;

    if (userCountry) {
      const currentOption = countryOptions.find(
        ({ label }: IRegionOption) => label === userCountry
      );

      if (!currentOption) return;

      setDefaultCountry(currentOption);
      handleCountrySelect(currentOption, true);
    }
  }, [user]);

  if (!user) return null;

  const onSubmit = ({
    country,
    city,
    name,
    email,
    companyName,
  }: IUpdateData) => {
    if (!country) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("chooseCountry")}`,
      });
      setErrorField("country");
      return;
    }

    if (!city) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("chooseState")}`,
      });
      setErrorField("city");
      return;
    }

    const request: IUpdateData = {};
    let isEmailChange = false;

    if (name !== user.name) {
      request.name = name;
    }

    if (email !== "" && email !== user.email) {
      request.email = email;
      isEmailChange = true;
    }

    if (country !== user.country) {
      request.country = country;
    }

    if (city !== user?.state) {
      request.state = city;
    }

    if (companyName !== "" && companyName !== user.companyName) {
      request.companyName = companyName;
    } else if (companyName === "") {
      request.companyName = null;
    }

    if (!request || Object.keys(request).length === 0) {
      createNotification({
        title: i18n.t("noChange"),
        message: "",
        type: "warning",
      });
      return;
    }

    dispatch(updateUser(request, true, isEmailChange));
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("change")}>
      {isLoading && <Loader />}
      <FormLayoutItem width="fifty">
        <InputField
          label={`${i18n.t("name")}`}
          id="change_name"
          register={register("name")}
          defaultValue={user.name}
          required
        />
      </FormLayoutItem>
      <FormLayoutItem width="fifty">
        <InputField
          id="change_email"
          register={register("email")}
          type="email"
          label="Email"
          placeholder={`${i18n.t("enter")} Email`}
          defaultValue={user.email ? user.email : undefined}
          required={!!user.email}
        />
      </FormLayoutItem>
      <FormLayoutItem selectMod>
        <SelectField
          id="change_country"
          isError={errorField === "country"}
          onValueChange={(option) => handleCountrySelect(option)}
          options={countryOptions}
          label={i18n.t("country")}
          defaultValue={defaultCountry}
          key={JSON.stringify(defaultCountry)}
          name="country"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem selectMod>
        <SelectField
          onValueChange={(option) => handleRegionSelect(option.label)}
          options={regionData}
          isDisabled={country === null}
          defaultValue={defaultCity}
          key={`${JSON.stringify(defaultCity)}${cityKey}`}
          isError={errorField === "city"}
          label={i18n.t("region")}
          name="city"
          id="change_city"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <InputField
          id="change_company_name"
          register={register("companyName")}
          label={`${i18n.t("company")}`}
          placeholder={`${i18n.t("enterCompany")}`}
          defaultValue={user.companyName ? user.companyName : undefined}
        />
      </FormLayoutItem>
      {isInfoPopupOpen && (
        <Popup
          size="xxs"
          isOpen={isInfoPopupOpen}
          handleClose={handlePopupClose}
        >
          <div className="edit_form__content">
            <div className="edit_form__title">{i18n.t("confirmEmail")}</div>
            <div className="edit_form__text">
              <p>{i18n.t("confirmEmailText1")}</p>
              <p>{i18n.t("confirmEmailText2")}</p>
            </div>
            <button
              type="button"
              onClick={handlePopupClose}
              className="edit_form__button"
            >
              {i18n.t("ok")}
            </button>
          </div>
        </Popup>
      )}
    </FormLayout>
  );
};

export default EditProfileForm;
