import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { confirmPassword } from "../../store/user/useCases/confirmPassword/action";
import i18n from "../../locales";
import { FormLayout, FormLayoutItem } from "../formLayout";
import PasswordInput from "../passwordInput/passwordInput";
import { createNotification } from "../../utils/createNotification";

interface IPasswordData {
  password: string;
  confirm_password: string;
}

const CreatePasswordForm = () => {
  const { register, handleSubmit } = useForm<IPasswordData>();
  const dispatch = useDispatch();

  const onSubmit = (data: IPasswordData) => {
    const { password, confirm_password } = data;

    if (password.length < 6) {
      createNotification({
        type: "danger",
        title: "",
        message: i18n.t("passwordLength"),
      });
      return;
    }

    if (password !== confirm_password) {
      createNotification({
        type: "danger",
        title: "",
        message: i18n.t("passwordnotSame"),
      });
      return;
    }

    dispatch(confirmPassword(password));
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("confirm")}>
      <FormLayoutItem>
        <h3>{i18n.t("confirmPass")}</h3>
      </FormLayoutItem>
      <FormLayoutItem>
        <PasswordInput
          register={register("password")}
          placeholder={`${i18n.t("pass")}`}
          autoComplete="new-password"
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <PasswordInput
          register={register("confirm_password")}
          placeholder={`${i18n.t("confirmPass")}`}
          autoComplete="confirm-new-password"
        />
      </FormLayoutItem>
    </FormLayout>
  );
};

export default CreatePasswordForm;
