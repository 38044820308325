import { FC, ReactNode } from "react";
import classNames from "classnames";
import "./section.scss";

interface IProps {
  children: ReactNode;
  className: string;
  noMaxWidth?: boolean;
}

const Section: FC<IProps> = ({ children, className, noMaxWidth }) => {
  return (
    <section className={classNames("section", className)}>
      {noMaxWidth ? children : <div className="section_in">{children}</div>}
    </section>
  );
};

export default Section;
