export const transformDate = (
  created_at: string,
  withTime?: boolean
): string => {
  const date = new Date(created_at);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  let formattedDate = `${year}-${month}-${day}`;

  if (withTime) {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    formattedDate += ` ${hours}:${minutes}`;
  }

  return formattedDate;
};
