import { FC, useEffect } from "react";
import classNames from "classnames";

import "./loader.scss";
import { CLASSES } from "../../constants";

interface IProps {
  isLocal?: boolean;
  btnStyle?: boolean;
}

const Loader: FC<IProps> = ({ btnStyle = false, isLocal = false }) => {
  useEffect(() => {
    if (isLocal || btnStyle) return;

    document.body.classList.add(CLASSES.bodyScrollHide);
    return () => {
      document.body.classList.remove(CLASSES.bodyScrollHide);
    };
  }, [isLocal, btnStyle]);

  return (
    <div
      className={classNames("loader", {
        "loader--fixed_mod": !isLocal && !btnStyle,
        "loader--local_mod": isLocal,
        "loader--btn_mod": btnStyle,
      })}
    >
      <div className="loader__in">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
