import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../store/orders/useCases/getCart/action";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import OrderingSection from "../../ui-kit/orderingSection/orderingSection";

const OrderingPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);

  useEffect(() => {
    if (isLoggedIn === "true") dispatch(getCart());
  }, [isLoggedIn]);

  return <OrderingSection />;
};

export default OrderingPage;
