import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { INotificationReadRequest } from "../../repository/INotificationReadRequest";

interface ISetNotificationsReadWorker {
  type: string;
  payload: INotificationReadRequest;
};

function* setNotificationsReadWorker({
  payload,
}: ISetNotificationsReadWorker): SagaIterator {
  try {
    const request = yield call(requester.put, links.notificationRead, payload);
  } catch (error) {
    console.error("setNotificationsReadWorker: ", error);
  }
};

export function* setNotificationsReadWatcher() {
  yield takeLatest("@saga/SET_NOTIFICATION_READ", setNotificationsReadWorker);
};
