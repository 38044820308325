import { useRef, FC } from "react";
import classNames from "classnames";
import { useMedia } from "../../customHooks";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import DropdownItem from "../dropdownItem/dropdownItem";
import { transformValueToVH } from "../../utils/calculateHeight";
import "./dropdown.scss";

interface IProps {
  items: ICatalog[];
  isOpen: boolean;
  depthLevel: number;
}

const Dropdown: FC<IProps> = ({ items, isOpen, depthLevel }) => {
  const isMobile = useMedia();
  const contentRef = useRef<HTMLDivElement>(null);

  const currentHeight = isOpen ? contentRef.current?.scrollHeight : 0;
  const maxHeight = isMobile
    ? `${currentHeight !== undefined && transformValueToVH(currentHeight)}`
    : undefined;
  const dropdownClasses = classNames("dropdown", {
    "dropdown--open_state": isOpen,
  });

  return (
    <div
      ref={isMobile ? contentRef : null}
      style={{ maxHeight }}
      className={dropdownClasses}
    >
      <ul className="dropdown__list">
        {items.map((item: ICatalog) => {
          return (
            <li key={item.id} className="dropdown__item">
              <DropdownItem item={item} depthLevel={depthLevel + 1} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
